import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNormali, formatRupiah } from "../../../../../utils";
import { InputLabel } from "../../../../molecules";
import { ModalCenter } from "../../../../organisms";

function ManageMaster({
  ModalShow,
  handleClose,
  ModalTitle,
  ModalCategory,
  data,
  onDelete,
  onSave,
  onEdit,
}) {
  const { productCategoryVal } = useSelector((state) => state.globalReducer);
  const [id, setId] = useState(0);
  const [nama, setNama] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [aktif, setAktif] = useState(true);
  const [amount, setAmount] = useState(true);
  const [namaError, setNamaError] = useState("");
  const [deskripsiError, setDeskripsiError] = useState("");
  const [amountError, setAmountError] = useState(true);
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);

  useEffect(() => {
    if (data !== 0) {
      setId(data.uuid);
      setNama(data.nama);
      setDeskripsi(data.deskripsi);
      setAktif(data.aktif);
      setAmount(formatRupiah(String(data.amount), "Rp. "));
    } else {
      setId(0);
      setNama("");
      setDeskripsi("");
      setAktif(true);
      setAmount("");
    }
  }, [data]);

  const validation = (val) => {
    let error = 0;
    if (nama.length < 5) {
      setNamaError("Nama kurang dari 5 karakter");
      error += 1;
    } else {
      setNamaError("");
    }

    if (deskripsi.length < 10) {
      setDeskripsiError("deskrispi kurang dari 10 karakter");
      error += 1;
    } else {
      setDeskripsiError("");
    }

    if (amount === undefined) {
      setAmountError("Minimal Rp 100.000");
      error += 1;
    } else if (amount.length < 11) {
      setAmountError("Minimal Rp 100.000");
      error += 1;
    } else {
      setAmountError("");
    }

    if (error === 0) {
      if (val === "save") {
        setSave(true);
        save();
      } else {
        setUpdate(true);
        edit();
      }
    }
  };

  const save = () => {
    const data = new FormData();
    data.append("name", nama);
    data.append("product_category_id", productCategoryVal.id);
    data.append("active", aktif);
    data.append("description", deskripsi);
    data.append("semester_fees", false);
    data.append("amount", formatNormali(amount));
    onSave(data, () => {
      setSave(false);
      handleClose();
    });
  };

  const edit = () => {
    const data = new FormData();
    data.append("_method", "PATCH");
    data.append("name", nama);
    data.append("product_category_id", productCategoryVal.id);
    data.append("active", aktif);
    data.append("description", deskripsi);
    data.append("semester_fees", false);
    data.append("amount", formatNormali(amount));
    onEdit(data, id, () => {
      setUpdate(false);
      handleClose();
    });
  };

  return (
    <ModalCenter
      show={ModalShow}
      handleClose={handleClose}
      size={"md"}
      title={ModalTitle}
      category={ModalCategory}
      onSave={() => {
        validation("save");
      }}
      onUpdate={() => {
        validation("update");
      }}
      onDelete={() => {
        handleClose();
        onDelete(id);
      }}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row px-2">
        <InputLabel
          label={"Nama"}
          placeholder={"Nama"}
          value={nama}
          type={"text"}
          onChange={(e) => setNama(e)}
          error={namaError}
          grid={"col-12"}
        />
        <InputLabel
          label={"Deskripsi"}
          placeholder={"Deskripsi"}
          value={deskripsi}
          type={"textarea"}
          onChange={(e) => setDeskripsi(e)}
          error={deskripsiError}
          grid={"col-12"}
        />

        <InputLabel
          label={"Biaya"}
          placeholder={"Rp. 100.000"}
          value={formatRupiah(String(amount), "Rp. ")}
          type={"text"}
          onChange={(e) => setAmount(e)}
          error={amountError}
          grid={"col-6"}
        />

        <InputLabel
          label={"Aktif"}
          placeholder={"Aktif"}
          value={aktif}
          type={"switch"}
          onChange={() => setAktif(aktif ? false : true)}
          grid={"col-6"}
        />
      </div>
    </ModalCenter>
  );
}

export default ManageMaster;
