import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  patchDataAPI,
  postDataAPI,
} from "../../../../../configs/redux/action/globalAction";
import {
  emaiValidation,
  notificationBody,
  passwordValidation,
} from "../../../../../utils";
import { InputLabel } from "../../../../molecules";
import { ModalCenter } from "../../../../organisms";

function ManagementUserModal({
  ModalShow,
  handleClose,
  ModalTitle,
  ModalCategory,
  data,
  onDelete,
  onSuccess,
}) {
  const { environment } = useSelector((state) => state.globalReducer);
  const { roleData } = useSelector((state) => state.roleDataReducer);

  const [id, setId] = useState(0);
  const [role, setRole] = useState(roleData[0].id);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [identityNumberError, setIdentityNumberError] = useState("");
  const [status, setStatus] = useState(true);
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const dispatch = useDispatch();

  const selectRole = (id) => {
    for (let i = 0; i < roleData.length; i++) {
      let val = roleData[i];
      if (val.id === id) {
        return val.id;
      }
    }
  };

  useEffect(() => {
    if (data !== 0) {
      setId(data.uuid);
      setRole(selectRole(data.role_id).toString());
      setName(data.name);
      setEmail(data.email);
      setIdentityNumber(data.identity_number);
      setPassword("");
      setStatus(data.status);
    } else {
      setId(0);
      setRole(roleData[0].id);
      setName("");
      setEmail("");
      setIdentityNumber("");
      setPassword("");
      setStatus(true);
    }
  }, [data]);

  const validation = (val) => {
    let error = 0;
    const passwordConfirm = passwordValidation(password);

    if (identityNumber.length !== 11) {
      setIdentityNumberError("Panjang karakter harus 11");
      error += 1;
    } else {
      setIdentityNumberError("");
    }

    if (name.length < 3) {
      setNameError("Panjang karakter harus lebih dari 3");
      error += 1;
    } else {
      setNameError("");
    }

    if (emaiValidation(email) === null) {
      setEmailError("Email tidak benar");
      error += 1;
    } else {
      setEmailError("");
    }

    if (val === "save") {
      if (passwordConfirm !== "success") {
        setPasswordError(passwordConfirm);
        error += 1;
      } else {
        setPasswordError("");
      }
    } else {
      if (password.length > 0) {
        if (passwordConfirm !== "success") {
          setPasswordError(passwordConfirm);
          error += 1;
        } else {
          setPasswordError("");
        }
      }
    }

    if (error === 0) {
      if (val === "save") {
        setSave(true);
        onSave();
      } else {
        setUpdate(true);
        onUpdate();
      }
    }
  };

  const onSave = () => {
    const data = new FormData();
    data.append("role_id", role);
    data.append("name", name);
    data.append("email", email);
    data.append("identity_number", identityNumber);
    data.append("status", status);
    data.append("password", password);

    dispatch(
      postDataAPI(
        {
          url: environment.api + "/api/users/store",
          data,
          contentType: "multipart/form-data",
        },
        dispatch
      )
    )
      .then((success) => {
        if (success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        setSave(false);
        return onSuccess();
      })
      .catch((err) => {
        setSave(false);
        notificationBody(err.response.data.message, "", "error");
        return handleClose();
      });
  };

  const onUpdate = () => {
    const data = new FormData();
    data.append("_method", "PATCH");
    data.append("role_id", role);
    data.append("name", name);
    data.append("email", email);
    data.append("identity_number", identityNumber);
    data.append("status", status);

    dispatch(
      patchDataAPI(
        {
          url: environment.api + "/api/users/update/" + id,
          data,
          contentType: "multipart/form-data",
        },
        dispatch
      )
    )
      .then((success) => {
        if (success.status === 200) {
          notificationBody("Data berhasil diupdate", "", "success");
        }
        setUpdate(false);
        return onSuccess();
      })
      .catch((err) => {
        setUpdate(false);
        notificationBody("Data tidak berhasil diupdate", "", "error");
        return handleClose();
      });
  };

  return (
    <ModalCenter
      show={ModalShow}
      handleClose={handleClose}
      size={"md"}
      title={ModalTitle}
      category={ModalCategory}
      onSave={() => {
        validation("save");
      }}
      onUpdate={() => {
        validation("update");
      }}
      onDelete={() => {
        handleClose();
        onDelete(id);
      }}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row d-flex justify-content-center">
        <div className="row">
          <InputLabel
            label={"Role"}
            data={roleData}
            value={role}
            type={"select"}
            onChange={(e) => setRole(e)}
            grid={"col-md-12 col-lg-6"}
          />

          <InputLabel
            label={"Nomor Identitas"}
            placeholder={"contoh : 112112"}
            value={identityNumber}
            type={"text"}
            onChange={(e) => setIdentityNumber(e)}
            grid={"col-md-12 col-lg-6"}
            error={identityNumberError}
          />

          <InputLabel
            label={"Nama"}
            placeholder={"contoh : Irman Wahyudi"}
            value={name}
            type={"text"}
            onChange={(e) => setName(e)}
            grid={"col-md-12 col-lg-6"}
            error={nameError}
          />

          <InputLabel
            label={"Email"}
            placeholder={"contoh : IrmanWahyudi@gmail.com"}
            value={email}
            type={"text"}
            onChange={(e) => setEmail(e)}
            grid={"col-md-12 col-lg-6"}
            error={emailError}
          />

          <InputLabel
            label={"Password"}
            placeholder={"contoh : 1@1!2#qwe1231!"}
            value={password}
            type={"password"}
            onChange={(e) => setPassword(e)}
            grid={"col-md-12 col-lg-6"}
            error={passwordError}
          />
          <InputLabel
            label={"Status"}
            placeholder={"Status"}
            value={status}
            type={"switch"}
            onChange={() => setStatus(status ? false : true)}
            grid={"col-md-12 col-lg-6"}
          />
        </div>
      </div>
    </ModalCenter>
  );
}

export default ManagementUserModal;
