import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataAPI,
  patchDataAPI,
} from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";
import { Gap, Label } from "../../atoms";
import moment from "moment";
import { onMessageListener } from "../../../configs/firebase";

function OverlayNotification() {
  const { environment } = useSelector((state) => state.globalReducer);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(false);
  const [bellCount, setBellCount] = useState(0);
  const [update, setUpdate] = useState(0)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/notifikasi",
        data: {
          per_page: 5,
          student_id: 1,
        },
      })
    )
      .then((res) => {
        setData(res.data);
        // setDataLength(res.data.length >= 5 ? true : false);
        setDataLength(true);
        setBellCount(res.unread);
      })
      .catch(() => {
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, [update]);

  onMessageListener()
  .then((payload) => {
    //must be rendered on state on refresh
    notificationBody(
      payload.notification.title,
      payload.notification.body,
      "success"
    );
    setTimeout(() => {
      // call api notif
      setUpdate(update + 1);
    }, 1000);
  })
  .catch((err) => {
    notificationBody("Gagal Mendapatkan Notif", "error");
  });

  const routeNotification = () => {
    const data = new FormData();
    data.append("_method", "PATCH");
    data.append("status", 0);

    dispatch(
      patchDataAPI({
        url: environment.api + "/api/notifikasi/update/1",
        data,
      })
    )
      .then(() => {
        window.location.href = "/notifikasi";
      })
      .catch(() => {
        window.location.href = "/notifikasi";
      });
  };

  return (
    <button
      className="nav-item dropdown px-3 d-flex pe-2 border-0 bg-transparent align-items-center cursor-pointer position-relative shadow-none"
      onClick={() => setShow(!show)}
      onBlur={() => setShow(false)}
    >
      <i className="fa fa-bell cursor-pointer text-white">
        {bellCount !== 0 && (
          <div className="position-absolute" style={{ top: -5, left: 22 }}>
            <div
              className="bg-danger p-1 rounded-3"
              style={{ fontSize: 10, fontWeight: "bold" }}
            >
              {bellCount}
            </div>
          </div>
        )}
      </i>

      {show && data.length > 0 && (
        <>
          <i className="fa fa-caret-up position-absolute text-white mt-4 shadow"></i>
          <div
            className="position-absolute top-100 end-2 mt-1 mt-sm-1 mt-sm-0 bg-white pt-2 pb-1 rounded list-unstyled shadow"
            style={{ minWidth: 250, zIndex: 999999 }}
          >
            {data.map((val, key) => {
              return (
                <div
                  key={key}
                  className={`hover-li text-dark text-start ps-3 py-1 border-bottom mx-2 py-2 ${val.status !== 0? 'bg-light':''}`}
                >
                  <Label
                    fontSize={12}
                    className={
                      val.status === 0
                        ? "text-secondary fw-bold"
                        : "text-dark fw-bold"
                    }
                  >{`${val.title} - ${val.no_order}`}</Label>
                  <Label
                    fontSize={10}
                    className={
                      val.status === 0 ? "text-secondary" : "text-dark"
                    }
                  >
                    {moment(new Date(val.created_at), "YYYYMMDD").fromNow()}
                  </Label>
                </div>
              );
            })}

            {dataLength ? (
              <div
                className="hover-li text-dark text-start  py-1 border-bottom py-2 d-flex justify-content-center"
                onClick={routeNotification}
              >
                <Label>Lihat semua notifikasi</Label>
              </div>
            ) : (
              <Gap height={10} />
            )}
          </div>
        </>
      )}
    </button>
  );
}

export default OverlayNotification;
