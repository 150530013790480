import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchDataAPI } from "../../../../../configs/redux/action/globalAction";
import { Input } from "../../../../atoms";
import { InputLabel } from "../../../../molecules";

function ManagementActionCheckbox({ data, update }) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [checked, setChecked] = useState(false);
  const [change, setChange] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (update === "index") {
      setChecked(data.index);
    } else if (update === "store") {
      setChecked(data.store);
    } else if (update === "update") {
      setChecked(data.update);
    } else if (update === "destroy") {
      setChecked(data.destroy);
    } else {
      setChecked(false);
    }
  }, [data]);

  useEffect(() => {
    if (change > 0) {
      let formData = new FormData();
      formData.append("_method", "PATCH");
      formData.append(update, checked);

    //   console.log(formData);

      dispatch(
        patchDataAPI({
          url: environment.api + "/api/auth/role-task/update/" + data.uuid,
          data: formData,
          contentType: "multipart/form-data",
        })
      )
        .then((res) => {
        //   console.log(res);
        })
        .catch((err) => {
        //   console.log(err);
        });
    }
  }, [change]);

  return (
    <InputLabel
      label={""}
      placeholder={""}
      value={checked}
      type={"switch"}
      onChange={() => {
        setChecked(checked ? false : true);
        setChange(change + 1);
      }}
    />
  );
}

export default ManagementActionCheckbox;
