import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../../../../../configs/redux/action/globalAction";
import {
  convertEpochDate,
  formatRupiah,
  notificationBody,
} from "../../../../../utils";
import { Gap, Label } from "../../../../atoms";
import { TablePagination } from "../../../../organisms";

function CreateTransactionDetailModal({ show, handleClose, data }) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [dataTransactions, setDataTransactions] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const [paramsData, setParamsData] = useState({
    page: 1,
    per_page: 10,
  });
  const dispatch = useDispatch();

  const header = [
    "TANGGAL",
    "NAMA",
    "NAMA TRANSAKSI",
    "SEMESTER",
    "METODE",
    "STATUS",
    "AMOUNT",
  ];

  useEffect(() => {
    if (data !== null) {
      paramsData.semester = data.semester;
      paramsData.student_id = data.student_id;

      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction",
          data: paramsData,
        })
      )
        .then((res) => {
          setDataLength(res.total);
          setDataTransactions(
            res.data.map((val, key) => {
              return (
                <tr
                  key={key}
                  className={`text-white ${
                    val.amount < 0
                      ? "bg-info"
                      : val.status === 2
                      ? "bg-success"
                      : val.status === 1
                      ? "bg-warning"
                      : "bg-danger"
                  }`}
                  style={{ fontWeight: "bold", opacity: 0.9 }}
                >
                  <td>{convertEpochDate(val.updated_at || val.created_at)}</td>
                  <td>{val.product_name}</td>
                  <td>{val.product_category_name}</td>
                  <td>{val.semester}</td>
                  <td>{val.payment_accept_title || "Data Manual"}</td>
                  <td>{val.amount > 0 ? "Pembayaran" : "Tagihan"}</td>
                  <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
                </tr>
              );
            })
          );
        })
        .catch((err) => {
          notificationBody(
            "Terjadi kesalahan",
            "data tidak berhasil di tampilkan",
            "error"
          );
          setDataTransactions([]);
        });
    } else {
      setDataTransactions(null);
    }
  }, [data, paramsData]);

  return (
    <Modal
      show={show}
      size={"xl"}
      onHide={handleClose}
      centered
      style={{ zIndex: 99999 }}
    >
      <Modal.Header className="py-2 d-flex justify-content-between">
        <Modal.Title style={{ color: "black", fontSize: 18 }}>
          Data Semester {data !== null ? data.semester : ""}
        </Modal.Title>
        <div className="fs-1 cursor-pointer" onClick={handleClose}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-7 col-12 border border-1 border-primary px-3 py-2 rounded mb-3">
          <div className="d-flex flex-row justify-content-center">
            <Label style={{ fontWeight: "bold", color: "black", fontSize: 14 }}>
              Pembayaran Sukses <div className="bg-success p-2 opacity-8"></div>
            </Label>
            <Gap width={10} />
            <Label style={{ fontWeight: "bold", color: "black", fontSize: 14 }}>
              Pembayaran Pending{" "}
              <div className="bg-warning p-2 opacity-8"></div>
            </Label>
            <Gap width={10} />
            <Label style={{ fontWeight: "bold", color: "black", fontSize: 14 }}>
              Pembayaran Gagal <div className="bg-danger p-2 opacity-8"></div>
            </Label>
            <Gap width={10} />
            <Label style={{ fontWeight: "bold", color: "black", fontSize: 14 }}>
              Data Tagihan <div className="bg-info p-2 opacity-8"></div>
            </Label>
          </div>
        </div>
        <TablePagination
          header={header}
          data={dataTransactions}
          currentPage={paramsData.page}
          pageSize={paramsData.per_page}
          totalCount={dataLength}
          setCurrentPage={(page) => {
            setDataTransactions(null);
            setParamsData({ ...paramsData, page });
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default CreateTransactionDetailModal;
