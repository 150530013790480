const initialState = {
  roleData: null,
  roleFilter: null,
  roleFilterSelected: null,
};

const roleDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_ROLE":
      let data = [];
      // let filter = [{ id: 0, name: "Semua" }]
      let filter = [];

      for (let i = 0; i < action.payload.data.length; i++) {
        const val = action.payload.data[i];
        if (val.name !== "Mahasiswa") {
          data.push({ id: val.id, name: val.name });
          filter.push({ id: val.id, name: val.name });
        }
      }

      data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      filter.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

      return {
        ...state,
        roleData: data,
        roleFilter: filter,
        // roleFilterSelected: { id: 0, name: "Semua" },
        roleFilterSelected: { id: 1, name: "Kepala Keuangan" },
      };
    case "GET_DATA_ROLE_WITH_ALL":
      let data_all = [];
      let filter_all = [{ id: 0, name: "Semua" }];

      for (let i = 0; i < action.payload.data.length; i++) {
        const val = action.payload.data[i];
        if (val.name !== "Mahasiswa") {
          data_all.push({ id: val.id, name: val.name });
          filter_all.push({ id: val.id, name: val.name });
        }
      }

      data_all.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      filter_all.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

      return {
        ...state,
        roleData: data_all,
        roleFilter: filter_all,
        roleFilterSelected: { id: 0, name: "Semua" },
      };
    case "UPDATE_ROLE_FILTER":
      return {
        ...state,
        roleFilterSelected: action.payload,
      };
    default:
      return state;
  }
};

export default roleDataReducer;
