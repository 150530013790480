import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import sessionReducer from "./sessionReducer";
import dataSppReducer from "./dataSppReducer";
import paymentReducer from "./paymentReducer";
import dataDppReducer from "./dataDppReducer";
import yearsReducer from "./yearsReducer";
import classCategoryReducer from "./classCategoryReducer";
import classMajorReducer from "./classMajorReducer";
import semesterReducer from "./semesterReducer";
import dataBeasiswaReducer from "./dataBeasiswaReducer";
import dataSeminarReducer from "./dataSeminarReducer";
import dataSertifikasiReducer from "./dataSertifikasiReducer";
import approvalDataReducer from "./approvalDataReducer";
import roleDataReducer from "./roleDataReducer";
import userDataReducer from "./userDataReducer";
import studentDataReducer from "./studentDataReducer";
import transactionDataReducer from "./transactionDataReducer";
import productCategoryReducer from "./productCategoryReducer";
import productDataReducer from "./productDataReducer";
import schoolYearDataReducer from "./schoolYearDataReducer";
import dataTagihanReducer from "./dataTagihanReducer";
import studentCategoryReducer from "./studentCategoryReducer";

const reducer = combineReducers({
  globalReducer,
  sessionReducer,
  dataSppReducer,
  paymentReducer,
  dataDppReducer,
  yearsReducer,
  classCategoryReducer,
  classMajorReducer,
  semesterReducer,
  dataBeasiswaReducer,
  dataSeminarReducer,
  dataSertifikasiReducer,
  approvalDataReducer,
  roleDataReducer,
  userDataReducer,
  studentDataReducer,
  transactionDataReducer,
  productCategoryReducer,
  productDataReducer,
  schoolYearDataReducer,
  dataTagihanReducer,
  studentCategoryReducer,
});

export default reducer;
