import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postDataAPI } from "../../../../configs/redux/action/globalAction";
import { formatRupiah, notificationBody } from "../../../../utils";
import { InputLabel } from "../../../molecules";

function DataStudentModal({ show, handleClose, data }) {
  const { environment } = useSelector((state) => state.globalReducer);
  const { studentCategoryFilter } = useSelector(
    (state) => state.studentCategoryReducer
  );
  const [studentCategory, setStudentCategory] = useState("Global");
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setStudentCategory(
        data.student_category_id === null ? "Global" : data.student_category_id
      );
    } else {
      setStudentCategory("Global");
      setUpdate(0);
    }
  }, [show]);

  useEffect(() => {
    if (update > 0) {
      changeStudentCategory();
    }
  }, [update]);

  const changeStudentCategory = () => {
    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("student_category_id", studentCategory);

    dispatch(
      postDataAPI({
        url: environment.api + "/api/student/update/" + data.uuid,
        data: formData,
      })
    )
      .then((success) => {
        if (success.status === 200 || success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        handleClose();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        handleClose();
      });
  };

  const DataRowLine = ({ name, value, colon }) => {
    return (
      <div className="d-flex align-items-baseline mb-2">
        <div className="col-4 fw-bold">{name}</div>
        {colon ? (
          <div className="col-8 d-flex align-items-baseline">
            <div className="me-1">:</div>
            <div className="flex-grow-1">{value}</div>
          </div>
        ) : (
          <div className="col-8">: {value}</div>
        )}
      </div>
    );
  };

  if (show) {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{ zIndex: 99999 }}
      >
        <Modal.Header className="py-2 d-flex justify-content-between">
          <Modal.Title style={{ color: "black", fontSize: 18 }}>
            Data Mahasiswa
          </Modal.Title>
          <div className="fs-1 cursor-pointer" onClick={handleClose}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body style={{ marginBottom: 30 }}>
          <div className="row">
            <DataRowLine name={"Nama"} value={data.name} />
            <DataRowLine name={"NPM"} value={data.npm} />
            <DataRowLine name={"Semester"} value={data.semester} />
            <DataRowLine name={"Kelas"} value={data.class_category_name} />
            <DataRowLine name={"Studi"} value={data.class_major_name} />
            <DataRowLine
              name={"Tagihan"}
              value={formatRupiah(String(data.amount_negative || 0), "Rp ")}
            />
            <DataRowLine
              name={"Bayar"}
              value={formatRupiah(String(data.amount_positive || 0), "Rp ")}
            />
            <DataRowLine
              name={"Sisa"}
              value={formatRupiah(String(data.amount), "Rp ")}
            />
            <DataRowLine name={"Nama"} value={data.name} />
            <DataRowLine
              name={"Tipe Persetujuan"}
              colon
              value={
                <InputLabel
                  label={""}
                  data={studentCategoryFilter}
                  value={studentCategory}
                  type={"select"}
                  onChange={(e) => {
                    setStudentCategory(e);
                    setTimeout(() => {
                      setUpdate(update + 1);
                    }, 500);
                  }}
                  grid={"col-md-12"}
                  style={{ width: "auto" }}
                />
              }
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DataStudentModal;
