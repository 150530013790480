import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountTemplate } from "../../../components/templates/admins";
import { patchDataAPI } from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";

function AccountPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const { sessionUser } = useSelector((state) => state.sessionReducer);
  const dispatch = useDispatch();

  const onUpdate = (data, type, cb) => {
    console.log(data);

    dispatch(
      patchDataAPI({
        url: environment.api + "/api/account/update/" + sessionUser.uuid,
        data,
      })
    )
      .then((success) => {
        if (success.status === 200 || success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        if (type === "account") {
          window.location.reload();
        } else {
          cb();
        }
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        cb();
      });
  };

  return <AccountTemplate data={sessionUser} onUpdate={onUpdate} />;
}

export default AccountPage;
