import React from "react";

function AlertOnBody({ head, body }) {
  return (
    <div className="d-flex flex-column">
      {head && <div className="fw-bold">{head}</div>}
      {body && <div style={{fontSize:12}}>{body}</div>}
    </div>
  );
}

export default AlertOnBody;
