import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Label } from "../../atoms";
import { Button } from "../../molecules";
import moment from "moment";
import { PdfDocument } from "./PdfDocument";

function ModalExport({ show, handleClose, dataExport, dataExportPdf, title }) {
  const [tipe, setTipe] = useState(0);
  const [data, setData] = useState(null);
  const [dataPdf, setDataPdf] = useState(null);
  const exportTipe = [{ name: "CSV" }, { name: "PDF" }];
  const [name, setName] = useState(`${title} - ${moment().format("LLL")}`);

  useEffect(() => {
    if (dataExport !== null) {
      setData(dataExport);
      setDataPdf(dataExportPdf);
    } else {
      setData(null);
      setDataPdf(null);
    }
  }, [dataExport]);

  const downloadPdf = () => {
    let header = Object.keys(data[0]);

    let headerRow = [];
    for (let i = 0; i < header.length; i += 1) {
      headerRow.push({
        id: header[i],
        name: header[i],
        prompt: header[i],
        align: "center",
        width: 270 / header.length,
        padding: 0,
      });
    }

    const toString = (o) => {
      Object.keys(o).forEach((k) => {
        if (typeof o[k] === "object") {
          return toString(o[k]);
        }

        o[k] = "" + o[k];
      });

      return o;
    };
    let dataRow = dataExportPdf.map((val) => {
      return toString(val);
    });

    return PdfDocument(title, dataRow, headerRow, name);
  };

  return (
    <Modal show={show} onHide={handleClose} centered style={{ zIndex: 99999 }}>
      <Modal.Header className="py-2 d-flex justify-content-between">
        <Modal.Title style={{ color: "black", fontSize: 18 }}>
          Ekspor Data
        </Modal.Title>
        <div className="fs-1 cursor-pointer" onClick={handleClose}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row justify-content-around">
          {exportTipe.map((val, key) => {
            return (
              <Button
                key={key}
                className={`${
                  key === tipe
                    ? tipe === 0
                      ? "bg-success"
                      : "bg-danger"
                    : "bg-light"
                } border-0 text-white w-100 mx-1 py-1 rounded`}
                label={<Label className={"fs-5 fw-bold"}>{val.name}</Label>}
                onClick={() => setTipe(key)}
              />
            );
          })}
        </div>
        {data === null ? (
          <div className="min-height-250 d-flex justify-content-center align-items-center">
            <i className="fa fa-circle-notch fa-spin fa-3x"></i>
          </div>
        ) : tipe === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <div className="my-5 d-flex flex-column justify-content-center align-items-center">
              <i className="fa fa-file-csv fa-4x text-info"></i>
              <h6 className="mt-3">{name}.csv</h6>
            </div>

            <CSVLink
              data={data}
              filename={name+'.csv'}
              target="_blank"
              className={"btn btn-primary w-50"}
            >
              Unduh
            </CSVLink>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <div className="my-5 d-flex flex-column justify-content-center align-items-center">
              <i className="fa fa-file-pdf fa-4x text-info"></i>
              <h6 className="mt-3">{name}.pdf</h6>
            </div>

            <Button
              label={<div style={{ fontSize: 14, padding: 2 }}>Unduh</div>}
              className={"btn btn-primary w-50"}
              onClick={() => downloadPdf()}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalExport;
