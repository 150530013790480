import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationTemplate } from "../../../components/templates/admins";
import { getDataAPI } from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";

function NotificationPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
  });
  const [more, setMore] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/notifikasi",
        data: params,
      })
    )
      .then((res) => {
        setData(res.data);
        res.current_page < res.total_page ? setMore(true) : setMore(false);
        dispatch({ type: "STATE_LOADING", payload: false });
      })
      .catch(() => {
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, [params]);

  return (
    <NotificationTemplate
      data={data}
      more={more}
      setParams={() => {
        setParams((prev) => {
          return {
            ...prev,
            page: prev.page + 1,
          };
        });
      }}
    />
  );
}

export default NotificationPage;
