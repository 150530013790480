import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Navbar, Sidebar, SidebarCanvas } from "../../../organisms";
import { useLocation } from "react-router-dom";
import { Footer } from "../../../molecules";

function AmdinLayout() {
  const { menuNavigation } = useSelector((state) => state.globalReducer);
  const [ShowCanvas, setShowCanvas] = useState(false);
  const handleCloseCanvas = () => setShowCanvas(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "STATE_LOADING", payload: true });
    handleCloseCanvas();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        handleCloseCanvas();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBrandText = (path) => {
    for (let i = 0; i < menuNavigation.length; i++) {
      let pathRoute = menuNavigation[i].url;
      if (path === pathRoute) {
        return {
          menu: menuNavigation[i].name,
          submenu: null,
        };
      }

      if (menuNavigation[i].child.length > 0) {
        for (let j = 0; j < menuNavigation[i].child.length; j++) {
          const childRoute = menuNavigation[i].child[j];
          let pathRoute = childRoute.url;
          if (path === pathRoute) {
            return {
              menu: menuNavigation[i].name,
              submenu: childRoute["name"],
            };
          }
        }
      }
    }
    return {
      menu: "menu",
      submenu: "sub menu",
    };
  };

  return (
    <div className="g-sidenav-show bg-gray-100">
      <div className="min-height-300 bg-primary position-absolute w-100"></div>
      <ToastContainer />
      <Sidebar
        routeArray={menuNavigation}
        urlPath={"/" + location.pathname.split("/")[1]}
      />
      <SidebarCanvas
        routeArray={menuNavigation}
        urlPath={"/" + location.pathname.split("/")[1]}
        show={ShowCanvas}
        handleClose={handleCloseCanvas}
      />
      <main className="main-content position-relative border-radius-lg ">
        <Navbar
          onShowCanvas={() => setShowCanvas(true)}
          brandText={getBrandText(location.pathname)}
          routeArray={menuNavigation}
        />
        <div className="container-fluid py-4">
          <div className="rounded-3 min-vh-100">
            <Outlet />
          </div>
          <Footer />
        </div>
      </main>
    </div>
  );
}

export default AmdinLayout;
