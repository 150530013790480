const initialState = {
    paymentAccept: null,
  };
  
  const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PAYMENT_ACCEPT":
        return {
          ...state,
          paymentAccept: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default paymentReducer;
  