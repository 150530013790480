import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../../../pages/maintenances";
import { ChartLine, ChartPie } from "../../../atoms";

function DashboardTemplate({ data }) {
  const navigate = useNavigate();
  const { stateLoading } = useSelector((state) => state.globalReducer);

  return (
    <div className="row">
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null ? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/spp")}
            >
              <div>
                <div>Data Spp</div>
                <div className="fw-bold h4">{data.dataSemester}</div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-money-bill text-success fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/dpp")}
            >
              <div>
                <div>Data Dpp</div>
                <div className="fw-bold h4">{data.dataProduct.Data_DPP}</div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-receipt text-warning fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/beasiswa")}
            >
              <div>
                <div>Data Beasiswa</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Data_Beasiswa}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-wallet text-danger fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/sertifikasi")}
            >
              <div>
                <div>Data Sertifikasi</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Data_Sertifikasi}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-money-check-alt text-info fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/akselerasi")}
            >
              <div>
                <div>Kelas Akselerasi</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Kelas_Akselerasi}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-gauge text-primary fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/semester-pendek")}
            >
              <div>
                <div>Semester Pendek</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Semester_Pendek}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-arrow-up-1-9 text-secondary fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/seminar")}
            >
              <div>
                <div>Seminar Sidang</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Biaya_Wisuda}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-handshake text-cyan fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6 col-lg-3 mb-1">
        <div className="bg-white rounded shadow px-3 py-2 cursor-pointer ">
          {stateLoading || data === null? (
            <LoadingState height={68} />
          ) : (
            <div
              className="d-flex align-items-center fade-page"
              onClick={() => navigate("/master/lain-lain")}
            >
              <div>
                <div>Data Biaya Lain-lain</div>
                <div className="fw-bold h4">
                  {data.dataProduct.Biaya_Lain_Lain}
                </div>
              </div>
              <div className="flex-grow-1 align-self-start text-end">
                <i className="fas fa-window-restore text-dark fa-3x"></i>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-1 col-12 col-md-6">
        {stateLoading || data === null? (
          <LoadingState height={230} shadow />
        ) : (
          <div className="bg-white rounded shadow px-1 py-2 fade-page">
            <h6 className="text-center">Bagan Jenis Pembayaran</h6>
            <ChartPie />
          </div>
        )}
      </div>
      <div className="p-1 col-12 col-md-6">
        {stateLoading || data === null? (
          <LoadingState height={230} shadow />
        ) : (
          <div className="bg-white rounded shadow px-1 py-2 fade-page">
            <h6 className="text-center">Bagan Tipe Pembayaran</h6>
            <ChartLine />
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardTemplate;
