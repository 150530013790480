const initialState = {
    resultDataSPP: null,
  };
  
  const dataSppReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_SPP":
        return {
          ...state,
          resultDataSPP: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default dataSppReducer;
  