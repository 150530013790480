import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDataAPI } from "../../../../../configs/redux/action/globalAction";
import { LoadingState } from "../../../../../pages/maintenances";
import { notificationBody } from "../../../../../utils";
import { handleDelete } from "../../../../../utils/sweetAlert";
import { FilterDropdown } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";
import ManagementUserModal from "./ManagementUserModal";

function ManagementUserTemplate({ data, setUpdate, page, setPage, size }) {
  const { stateLoading, environment } = useSelector((state) => state.globalReducer);
  const { roleData, roleFilter, roleFilterSelected } = useSelector(
    (state) => state.roleDataReducer
  );
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Tambah Data User");
  const [modalCategory, setModalCategory] = useState("save");
  const [dataUser, setDataUser] = useState(null);
  const dispatch = useDispatch();
  const header = ["NIK", "NAMA", "EMAIL", "STATUS", "PERAN", "HAPUS"];

  const selectRole = (id) => {
    for (let i = 0; i < roleData.length; i++) {
      let val = roleData[i];

      if (val.id === id) {
        return val.name;
      }
    }
  };

  const ClearState = () => {
    setModalTitle("Tambah Data User");
    setModalCategory("save");
    setManageData(0);
    return setModalShow(false);
  };

  const handleClose = () => {
    if (modalCategory !== "save") {
      ClearState();
    }

    return setModalShow(false);
  };

  useEffect(() => {
    if (data !== null) {
      setDataUser(
        data.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.identity_number}</td>
              <td>{val.name}</td>
              <td>{val.email}</td>
              <td>
                {val.status ? (
                  <div className="bg-gradient-success text-white text-center rounded fw-bold mx-3 mb-0">
                    Aktif
                  </div>
                ) : (
                  <div className="bg-gradient-danger text-white text-center rounded fw-bold mx-3 mb-0">
                    Tidak Aktif
                  </div>
                )}
              </td>
              <td>{selectRole(val.role_id)}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  onClick={() => {
                    setModalCategory("update");
                    setModalTitle("Ubah Data User");
                    setModalShow(true);
                    setManageData(val);
                  }}
                >
                  Ubah
                </button>
                <button
                  className="btn btn-sm btn-danger py-1 px-3 shadow-none mb-0"
                  onClick={() => onDelete(val.uuid)}
                >
                  Hapus
                </button>
              </td>
            </tr>
          );
        })
      );
      setDataLength(data.total);
    }
  }, [data]);

  const onDelete = (uuid) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI({
            url: environment.api + "/api/users/delete/" + uuid,
          })
        )
          .then((success) => {
            notificationBody(success.data.message, "", "success");
            setUpdate();
          })
          .catch((err) => {
            notificationBody(err.response.data.message, "", "error");
          });
      }
    });
  };

  if (stateLoading) return <LoadingState shadow />;

  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3">
        <button
          className="btn btn-sm btn-success col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 px-2 shadow-none"
          style={{ fontSize: 14 }}
          onClick={() => {
            // setTipe(DataTipe[0].id);

            setModalShow(true);
          }}
        >
          Tambah Data Pengguna
        </button>
      </div>
      <div
        className="mx-4 mb-3 px-3 py-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">Tabel Data Peran Pengguna</div>
        <div className="row justify-content-between">
          <div className="col-8 col-md-6 col-lg-4 col-xl-4 mb-2 ">
            {roleFilter !== null ? (
              <FilterDropdown
                title={"Peran"}
                data={roleFilter}
                value={roleFilterSelected}
                setValue={(e) => {
                  // setData(null);
                  // setModuleData(null);
                  setDataUser(null);
                  dispatch({ type: "UPDATE_ROLE_FILTER", payload: e });
                }}
              />
            ) : (
              <LoadingState height={20} />
            )}
          </div>
        </div>
        <div className="px-3 pb-4">
          {data !== null ? (
            <TablePagination
              header={header}
              data={dataUser}
              currentPage={page}
              pageSize={size}
              totalCount={dataLength}
              setCurrentPage={(page) => {
                setDataUser(null);
                setPage(page);
              }}
            />
          ) : (
            <LoadingState />
          )}
        </div>
      </div>
      {data !== null && (
        <ManagementUserModal
          ModalShow={modalShow}
          handleClose={handleClose}
          ModalTitle={modalTitle}
          ModalCategory={modalCategory}
          data={manageData}
          onDelete={(e) => onDelete(e)}
          onSuccess={() => {
            ClearState();
            setUpdate();
          }}
        />
      )}
    </div>
  );
}

export default ManagementUserTemplate;
