import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { convertEpochDate, formatRupiah } from "../../../../../utils";
import { Gap, Label } from "../../../../atoms";
import { Button } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";
import CreateTransactionAddData from "./CreateTransactionAddData";
import CreateTransactionDetailModal from "./CreateTransactionDetailModal";
import CreateTransactionFilter from "./CreateTransactionFilter";

function CreateTransctionTemplate({
  header,
  paramsData,
  options,
  setParamsData,
  setUpdate,
  setMahasiswa,
  mahasiswa,
  onDelete,
}) {
  const { stateLoading } = useSelector((state) => state.globalReducer);

  const {
    transactionData,
    transactionDataDpp,
    transactionDataWisuda,
    transactionDataRegister,
  } = useSelector((state) => state.transactionDataReducer);
  const { studentData } = useSelector((state) => state.studentDataReducer);

  const [data, setData] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const [dataDpp, setDataDpp] = useState(null);
  const [dataDppLength, setDataDppLength] = useState(0);
  const [dataWisuda, setDataWisuda] = useState(null);
  const [dataWisudaLength, setDataWisudaLength] = useState(0);
  const [dataRegister, setDataRegister] = useState(null);
  const [dataRegisterLength, setDataRegisterLength] = useState(0);
  const [manageData, setManageData] = useState(0);
  const [tagihan, setTagihan] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("Tambah Data");
  const [modalCategory, setModalCategory] = useState("save");
  const [datamahasiswa, setDatamahasiswa] = useState(null);
  const dispatch = useDispatch();

  const ClearState = () => {
    setModalTitle("Tambah Data Transaksi");
    setModalCategory("save");
    setManageData(0);
    return setModalShow(false);
  };

  const handleClose = () => {
    if (modalCategory !== "save") {
      ClearState();
    }

    return setModalShow(false);
  };

  useEffect(() => {
    if (
      transactionData !== null &&
      transactionDataDpp !== null &&
      transactionDataWisuda !== null
    ) {
      let total = 0;

      if (transactionData !== null) {
        setData(
          transactionData.data.map((val, key) => {
            total += parseInt(val.amount_total);
            return (
              <tr
                key={key}
                className="hover hover-li cursor-pointer"
                onClick={() => {
                  
                  setDataModal({
                    semester: val.semester,
                    student_id: datamahasiswa.id,
                  });
                  setModalDetail(!modalDetail)
                }}
              >
                <td>{val.semester}</td>
                <td>{formatRupiah(String(val.amount_bill), "Rp. ")}</td>
                <td>{formatRupiah(String(val.amount_pay), "Rp. ") || "-"}</td>
                <td>{formatRupiah(String(val.amount_total), "Rp. ")}</td>
              </tr>
            );
          })
        );
        setDataLength(transactionData.total);
      }

      if (transactionDataDpp !== null) {
        setDataDpp(
          transactionDataDpp.data.map((val, key) => {
            total += parseInt(val.amount_total);
            return (
              <tr key={key}>
                <td>{val.semester}</td>
                <td>{formatRupiah(String(val.amount_bill), "Rp. ")}</td>
                <td>{formatRupiah(String(val.amount_pay), "Rp. ") || "-"}</td>
                <td>{formatRupiah(String(val.amount_total), "Rp. ")}</td>
              </tr>
            );
          })
        );
        setDataDppLength(transactionDataDpp.total);
      }

      if (transactionDataWisuda !== null) {
        setDataWisuda(
          transactionDataWisuda.data.map((val, key) => {
            total += parseInt(val.amount_total);
            return (
              <tr key={key}>
                <td>{val.semester}</td>
                <td>{formatRupiah(String(val.amount_bill), "Rp. ")}</td>
                <td>{formatRupiah(String(val.amount_pay), "Rp. ") || "-"}</td>
                <td>{formatRupiah(String(val.amount_total), "Rp. ")}</td>
              </tr>
            );
          })
        );
        setDataWisudaLength(transactionDataWisuda.total);
      }

      if (transactionDataRegister !== null) {
        setDataRegister(
          transactionDataRegister.data.map((val, key) => {
            total += parseInt(val.amount_total);
            return (
              <tr key={key}>
                <td>{val.semester}</td>
                <td>{formatRupiah(String(val.amount_bill), "Rp. ")}</td>
                <td>{formatRupiah(String(val.amount_pay), "Rp. ") || "-"}</td>
                <td>{formatRupiah(String(val.amount_total), "Rp. ")}</td>
              </tr>
            );
          })
        );

        setDataRegisterLength(transactionDataRegister.total);
      }

      setTagihan(total);
    }
  }, [
    transactionData,
    transactionDataDpp,
    transactionDataWisuda,
    transactionDataRegister,
  ]);

  useEffect(() => {
    setData(null);
    setDataDpp(null);
    setDataWisuda(null);
    if (mahasiswa !== 0) {
      let array = studentData.data;
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (mahasiswa.value === element.id) {
          return setDatamahasiswa(element);
        }
      }
    } else {
      setDatamahasiswa(null);
    }
  }, [mahasiswa]);

  if (stateLoading)
    return (
      <div className="row">
        <div className="col-sm-12">
          <LoadingState height={150} />
        </div>
        <Gap height={10} />
        <div className="col-sm-12">
          <LoadingState height={500} />
        </div>
      </div>
    );

  return (
    <div className="row">
      <div className="col-12">
        <CreateTransactionFilter
          paramsData={paramsData}
          options={options}
          setParamsData={setParamsData}
          setUpdate={setUpdate}
          setMahasiswa={setMahasiswa}
          mahasiswa={mahasiswa}
        />
      </div>
      <div className="col-sm-12">
        <div className="bg-white rounded-3">
          <div className="fade-page shadow min-height-600">
            {mahasiswa === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className=" fw-bold fs-5 mt-3">
                  Mahasiswa belum di pilih
                </div>
              </div>
            ) : transactionData !== null &&
              transactionDataDpp !== null &&
              transactionDataWisuda !== null ? (
              <div className="py-2 px-3 fade-page">
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex flex-column fw-bold mb-3">
                    <div className="d-flex flex-row">
                      <div style={{ width: 120 }}>NPM</div>
                      {/* <div>: {mahasiswa.label.split(")")[0].substring(1)}</div> */}
                      <div>: {datamahasiswa !== null && datamahasiswa.npm}</div>
                    </div>
                    <div className="d-flex flex-row">
                      <div style={{ width: 120 }}>Nama</div>
                      {/* <div>: {mahasiswa.label.split(")")[1]}</div> */}
                      <div>
                        : {datamahasiswa !== null && datamahasiswa.name}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div style={{ width: 120 }}>Akun Virtual</div>
                      {/* <div>: {mahasiswa.label.split(")")[1]}</div> */}
                      <div>
                        : {datamahasiswa !== null && datamahasiswa.va_number}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 text-end">
                    <div className="row justify-content-end">
                      <div className="col-6 col-md-4 col-lg-5">
                        <Button
                          label={"Muat Ulang Data"}
                          className="btn btn-sm btn-info me-4"
                          onClick={() => {
                            dispatch({
                              type: "GET_DATA_TRANSACTION",
                              payload: null,
                            });
                            dispatch({
                              type: "GET_DATA_TRANSACTION_DPP",
                              payload: null,
                            });
                            dispatch({
                              type: "GET_DATA_TRANSACTION_WISUDA",
                              payload: null,
                            });
                            setUpdate();
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-3 col-lg-4">
                        <Button
                          label={"Tambah Data"}
                          className="btn btn-sm btn-success"
                          onClick={() => {
                            setModalShow(true);
                          }}
                        />
                      </div>
                      {data !== [] && (
                        <div className="col-12 col-md-5 col-lg-12 text-end pe-3 fw-bold">
                          <div className="text-danger fs-5">
                            Sisa tagihan {formatRupiah(String(tagihan), "Rp. ")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Gap height={30} />
                <div className="row">
                  <div className="col-12 col-lg-6 px-1 mb-3">
                    <div className="border border-1 rounded mb-3">
                      <div className="text-center">
                        <Label fontSize={18} style={{ fontWeight: "bold" }}>
                          Data SPP
                        </Label>
                      </div>
                      {transactionData !== null &&
                        (data === null ? (
                          <LoadingState height={300} />
                        ) : (
                          <TablePagination
                            header={header}
                            data={data}
                            currentPage={paramsData.page}
                            pageSize={paramsData.per_page}
                            totalCount={dataLength}
                            setCurrentPage={(page) => {
                              setData(null);
                              setParamsData({ ...paramsData, page });
                            }}
                          />
                        ))}
                    </div>
                    {/* <div className="border border-1 rounded">
                      <div className="text-center">
                        <Label fontSize={18} style={{ fontWeight: "bold" }}>
                          Data Registrasis
                        </Label>
                      </div>
                      {transactionDataRegister !== null &&
                        (dataRegister === null ? (
                          <LoadingState height={300} />
                        ) : (
                          <TablePagination
                            header={header}
                            data={dataRegister}
                            currentPage={paramsData.page}
                            pageSize={paramsData.per_page}
                            totalCount={dataRegisterLength}
                            setCurrentPage={(page) => {
                              setData(null);
                              setParamsData({ ...paramsData, page });
                            }}
                          />
                        ))}
                    </div> */}
                  </div>
                  <div className="col-12 col-lg-6 px-1 mb-3">
                    <div className="border border-1 rounded mb-3">
                      <div className="text-center">
                        <Label fontSize={18} style={{ fontWeight: "bold" }}>
                          Data DPP
                        </Label>
                      </div>
                      {transactionDataDpp !== null &&
                        (dataDpp === null ? (
                          <LoadingState height={300} />
                        ) : (
                          <TablePagination
                            header={header}
                            data={dataDpp}
                            currentPage={paramsData.page}
                            pageSize={paramsData.per_page}
                            totalCount={dataDppLength}
                            setCurrentPage={(page) => {
                              setData(null);
                              setParamsData({ ...paramsData, page });
                            }}
                          />
                        ))}
                    </div>
                    <div className="border border-1 rounded">
                      <div className="text-center">
                        <Label fontSize={18} style={{ fontWeight: "bold" }}>
                          Data Wisuda
                        </Label>
                      </div>
                      {transactionDataWisuda !== null &&
                        (dataWisuda === null ? (
                          <LoadingState height={300} />
                        ) : (
                          <TablePagination
                            header={header}
                            data={dataWisuda}
                            currentPage={paramsData.page}
                            pageSize={paramsData.per_page}
                            totalCount={dataWisudaLength}
                            setCurrentPage={(page) => {
                              setData(null);
                              setParamsData({ ...paramsData, page });
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <CreateTransactionAddData
                  ModalShow={modalShow}
                  handleClose={handleClose}
                  ModalTitle={modalTitle}
                  ModalCategory={modalCategory}
                  data={{
                    year_id: paramsData.year_id,
                    class_major_id: paramsData.class_major_id,
                    class_category_id: paramsData.class_category_id,
                    semester_id: paramsData.semester_id,
                    student_id: mahasiswa.value,
                  }}
                  // onDelete={(e) => onDelete(e)}
                  onSuccess={() => {
                    dispatch({
                      type: "GET_DATA_TRANSACTION",
                      payload: null,
                    });
                    dispatch({
                      type: "GET_DATA_TRANSACTION_DPP",
                      payload: null,
                    });
                    dispatch({
                      type: "GET_DATA_TRANSACTION_WISUDA",
                      payload: null,
                    });
                    ClearState();
                    setUpdate();
                  }}
                />
                <CreateTransactionDetailModal
                  show={modalDetail}
                  data={dataModal}
                  handleClose={() => {
                    setModalDetail(!modalDetail);
                    setDataModal(null);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTransctionTemplate;
