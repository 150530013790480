import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { Gap } from "../../../../atoms";
import OoopsPng from "../../../../../assets/img/opps.png";
import ApprovalNavTab from "./ApprovalNavTab";
import ApprovalFilter from "./ApprovalFilter";
import { approvalStatusData, notificationBody } from "../../../../../utils";
import { TablePagination } from "../../../../organisms";
import { handleValidation } from "../../../../../utils/sweetAlert";
import { patchDataAPI } from "../../../../../configs/redux/action/globalAction";

function ApprovalTemplate({
  header,
  page,
  size,
  setPage,
  dataFilter,
  approval,
  setApproval,
  title,
  status,
  setStatus,
  setUpdate,
}) {
  const { stateLoading, environment } = useSelector(
    (state) => state.globalReducer
  );
  const { approvalData } = useSelector((state) => state.approvalDataReducer);
  const [data, setData] = useState(null);
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (approvalData !== null) {
      setData(
        approvalData.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{1 + key}</td>
              <td>{val.student_npm}</td>
              <td style={{ width: 300 }}>{val.student_name}</td>
              <td style={{ width: 300 }}>
                {val.status === true ? (
                  <div className="d-flex  py-1">
                    <div
                      className="bg-gradient-primary text-white text-center fw-bold mx-3 px-3 py-1 rounded"
                      style={{ fontSize: 12 }}
                    >
                      Disetujui
                    </div>
                  </div>
                ) : val.status === false ? (
                  <div className="d-flex  py-1">
                    <div
                      className="bg-gradient-danger text-white text-center fw-bold mx-3 px-3 py-1 rounded"
                      style={{ fontSize: 12 }}
                    >
                      Tidak Disetujui
                    </div>
                  </div>
                ) : (
                  <div className="d-flex  py-1">
                    <div
                      className="bg-gradient-warning text-black-50 text-center fw-bold mx-3 px-3 py-1 rounded"
                      style={{ fontSize: 12 }}
                    >
                      Menunggu
                    </div>
                  </div>
                )}
              </td>
              <td>{`${val.school_year_name} (${val.school_year_code})`}</td>
              <td>
                {val.status === true ? (
                  <>
                    <button className="btn btn-sm btn-secondary mb-1 me-2 disabled">
                      Disetujui
                    </button>
                    <button
                      className="btn btn-sm btn-danger mb-1 me-2"
                      onClick={() => updateApproval(val.uuid, false)}
                    >
                      Tidak disetujui
                    </button>
                  </>
                ) : val.status === false ? (
                  <>
                    <button
                      className="btn btn-sm btn-success mb-1 me-2"
                      onClick={() => updateApproval(val.uuid, true)}
                    >
                      Disetujui
                    </button>
                    <button className="btn btn-sm btn-secondary mb-1 me-2 disabled">
                      Tidak disetujui
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-success mb-1 me-2"
                      onClick={() => updateApproval(val.uuid, true)}
                    >
                      Disetujui
                    </button>
                    <button
                      className="btn btn-sm btn-danger mb-1 me-2"
                      onClick={() => updateApproval(val.uuid, false)}
                    >
                      Tidak disetujui
                    </button>
                  </>
                )}
              </td>
            </tr>
          );
        })
      );

      setDataLength(approvalData.total);
    }

    return () => {
      dispatch({ type: "GET_APPROVAL_DATA", payload: null });
    };
  }, [approvalData]);

  const updateApproval = (uuid, val) => {
    handleValidation().then((res) => {
      if (res) {
        const data = new FormData();
        data.append("_method", "PATCH");
        data.append(String(approval), val);
        // return console.log(data);
        dispatch(
          patchDataAPI({
            url: environment.api + "/api/approval/update/" + uuid,
            data,
          })
        )
          .then((success) => {
            // if (success.status === 201) {
              notificationBody(success.data.message, "", "success");
            // }
            setUpdate();
          })
          .catch((err) => {
            notificationBody(err.response.data.message, "", "error");
          });
      }
    });
  };

  if (stateLoading) return <LoadingState />;
  return (
    <div className="pt-3 bg-white rounded-3">
      <div className="container mx-2 mt-2">
        <div className="fw-bold" style={{ fontSize: 16 }}>
          {title}
        </div>
        <Gap height={25} />
        <ApprovalNavTab
          data={dataFilter}
          onChange={(e) => {
            setData(null);
            setApproval(e);
          }}
          value={approval}
        />
        <Gap height={35} />
        <ApprovalFilter
          dataStatus={approvalStatusData}
          valueStatus={status}
          onChangeStatus={(e) => {
            setData(null);
            setStatus(e);
          }}
          changeData={() => setData(null)}
        />
        <Gap height={15} />
        <div className="px-3">
          {/* {approvalData !== null && data !== null && ( */}
          {/* {approvalData !== null && ( */}
          <TablePagination
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => setPage(page)}
          />
          {/* )} */}
        </div>
        <Gap height={100} />
        {/* {dataLength === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <img
              src={OoopsPng}
              className="img-fluid text-center px-2 px-sm-4 px-md-6 px-lg-8"
            />
            <div
              className="fw-bold mt-3 text-black-50"
              style={{ fontSize: 16 }}
            >
              Data Belum Tersedia!
            </div>
          </div>
        )} */}
        <Gap height={100} />
      </div>
    </div>
  );
}

export default ApprovalTemplate;
