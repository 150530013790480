import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { formatRupiah } from "../../../../../utils";
import { Button } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";
import FilterDataSpp from "./FilterDataSpp";
import ManageSpp from "./ManageSpp";

function DataSppTemplate({
  onDelete,
  header,
  page,
  setPage,
  size,
  setUpdate,
  onSave,
  onEdit,
}) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const { resultDataSPP } = useSelector((state) => state.dataSppReducer);

  const [data, setData] = useState(null);
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Tambah Data Spp");
  const [modalCategory, setModalCategory] = useState("save");

  const ClearState = () => {
    setModalTitle("Tambah Data Spp");
    setModalCategory("save");
    setManageData(0);
    return setModalShow(false);
  };

  const handleClose = () => {
    if (modalCategory !== "save") {
      ClearState();
    }

    return setModalShow(false);
  };

  useEffect(() => {
    if (resultDataSPP !== null) {
      let datas = [];

      resultDataSPP.data.map((val, key) => {
        datas.push(
          <tr key={key}>
            <td>{resultDataSPP.from + key}</td>
            <td>{val.year}</td>
            <td>{val.semester}</td>
            <td>{val.class_category}</td>
            <td>{val.class_major}</td>
            <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
            <td>
              <button
                className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                onClick={() => {
                  setModalCategory("update");
                  setModalTitle("Edit Data SPP");
                  setModalShow(true);
                  setManageData({
                    id: val.uuid,
                    angkatan: val.year_id,
                    kelas: val.class_category_id,
                    semester: val.semester_id,
                    studi: val.class_major_id,
                    ammount: val.amount,
                  });
                }}
              >
                Ubah
              </button>
              <button
                className="btn btn-sm btn-danger py-1 px-3 shadow-none mb-0"
                onClick={() => onDelete(val.uuid)}
              >
                Hapus
              </button>
            </td>
          </tr>
        );
      });

      setData(datas);
      setDataLength(resultDataSPP.total);
    }
  }, [resultDataSPP]);

  if (stateLoading) return <LoadingState shadow />;
  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3">
        <Button
          label={"Tambah Data SPP"}
          className="btn btn-sm btn-success col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 px-2 shadow-none"
          fontSize={14}
          onClick={() => setModalShow(!modalShow)}
        />
      </div>

      <div
        className="mx-4 mb-3 px-3 pt-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">Tabel Data SPP Per Angkatan</div>
        <FilterDataSpp setData={setData} />
      </div>
      <div className="px-3 pb-4">
        {resultDataSPP !== null && (
          <TablePagination
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => {
              setData(null);
              setPage(page);
            }}
          />
        )}
      </div>
      {resultDataSPP !== null && (
        <ManageSpp
          ModalShow={modalShow}
          handleClose={handleClose}
          ModalTitle={modalTitle}
          ModalCategory={modalCategory}
          data={manageData}
          onDelete={(e) => onDelete(e)}
          onSave={onSave}
          onEdit={onEdit}
        />
      )}
    </div>
  );
}

export default DataSppTemplate;
