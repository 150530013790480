import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateTransctionTemplate } from "../../../../components/templates/admins";
import {
  deleteDataAPI,
  getDataAPI,
} from "../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../utils";
import { handleDelete } from "../../../../utils/sweetAlert";

function CreateTransactionPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const { studentData } = useSelector((state) => state.studentDataReducer);
  const [paramsData, setParamsData] = useState({
    page: 1,
    per_page: 1000,
    year_id: 0,
    class_major_id: 0,
    class_category_id: 0,
    semester_id: 0,
  });
  const [update, setUpdate] = useState(0);
  const [options, setOptions] = useState([]);
  const [mahasiswa, setMahasiswa] = useState(0);
  const dispatch = useDispatch();

  const header = ["SEMESTER", "TOTAL TAGIHAN", "Bayar", "TAGIHAN (Rp)"];

  useEffect(() => {
    let yearsFilter = dispatch(
      getDataAPI({
        url: environment.api + "/api/years",
        type: "GET_DATA_YEARS",
      })
    );

    let classMajor = dispatch(
      getDataAPI({
        url: environment.api + "/api/classmajor",
        type: "GET_DATA_CLASS_MAJOR",
      })
    );

    let classCategory = dispatch(
      getDataAPI({
        url: environment.api + "/api/classcategory",
        type: "GET_DATA_CLASS_CATEGORY",
      })
    );

    let semesterData = dispatch(
      getDataAPI({
        url: environment.api + "/api/semesters",
        type: "GET_DATA_SEMESTER",
      })
    );

    Promise.all([yearsFilter, classMajor, classCategory, semesterData])
      .then((result) => {
        let smstr = result[3].data;
        smstr.sort((a, b) =>
          a.semester > b.semester ? 1 : b.semester > a.semester ? -1 : 0
        );

        setParamsData({
          ...paramsData,
          year_id: result[0].data[0].id,
          class_major_id: result[1].data[0].id,
          class_category_id: result[2].data[0].id,
          // semester_id: smstr[0].id,
        });

        dispatch({ type: "STATE_LOADING", payload: false });
        setUpdate(update + 1);
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  useEffect(() => {
    let data = paramsData;
    if (update > 0) {
      dispatch(
        getDataAPI({
          url: environment.api + "/api/student",
          type: "GET_DATA_STUDENT",
          data,
        })
      );
    }
  }, [update]);

  useEffect(() => {
    if (studentData !== null) {
      setOptions(
        studentData.data.map((val) => {
          return { value: val.id, label: `(${val.npm}) ${val.name}` };
        })
      );
    }
  }, [studentData]);

  useEffect(() => {
    if (mahasiswa !== 0) {
      let data = paramsData;
      data.student_id = mahasiswa.value;
      data.type = "spp";
      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction/sum/product",
          type: "GET_DATA_TRANSACTION",
          data,
        })
      );

      data.type = "dpp";
      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction/sum/product",
          type: "GET_DATA_TRANSACTION_DPP",
          data,
        })
      );

      data.type = "wisuda";
      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction/sum/product",
          type: "GET_DATA_TRANSACTION_WISUDA",
          data,
        })
      );

      data.type = "register";
      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction/sum/product",
          type: "GET_DATA_TRANSACTION_REGISTER",
          data,
        })
      );
    } else {
      setOptions([]);
    }
  }, [mahasiswa, update]);

  const onDelete = (uuid) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI(
            {
              url: environment.api + "/api/transaction/delete/" + uuid,
            },
            dispatch
          )
        )
          .then(() => {
            notificationBody("Data berhasil dihapus", "", "success");
            setUpdate(update + 1);
          })
          .catch(() => {
            notificationBody("Data tidak berhasil dihapus", "", "error");
          });
      }
    });
  };

  return (
    <CreateTransctionTemplate
      header={header}
      options={options}
      paramsData={paramsData}
      setParamsData={setParamsData}
      setUpdate={() => setUpdate(update + 1)}
      setMahasiswa={setMahasiswa}
      mahasiswa={mahasiswa}
      onDelete={onDelete}
    />
  );
}

export default CreateTransactionPage;
