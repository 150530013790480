import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { formatRupiah } from "../../../../../utils";
import { SearchTable } from "../../../../atoms";
import { Button } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";
import ManageMaster from "./ManageMaster";

function MasterDataTemplate({
  onDelete,
  header,
  page,
  setPage,
  size,
  search,
  titleModal,
  setSearch,
  setUpdate,
  onSave,
  onEdit,
  dataTable
}) {
  const { stateLoading } = useSelector((state) => state.globalReducer);

  const [data, setData] = useState(null);
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(titleModal.save);
  const [modalCategory, setModalCategory] = useState("save");


  const ClearState = () => {
    setModalTitle(titleModal.save);
    setModalCategory("save");
    setManageData(0);
    return setModalShow(false);
  };

  const handleClose = () => {
    if (modalCategory !== "save") {
      ClearState();
    }

    return setModalShow(false);
  };

  useEffect(() => {
    if (dataTable !== null) {
      setData(
        dataTable.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{dataTable.from + key}</td>
              <td>{val.name}</td>
              <td>{val.description}</td>
              <td>
                {val.active ? (
                  <div className="bg-gradient-success text-white text-center rounded fw-bold mx-3 mb-0">
                    Aktif
                  </div>
                ) : (
                  <div className="bg-gradient-danger text-white text-center rounded fw-bold mx-3 mb-0">
                    Tidak Aktif
                  </div>
                )}
              </td>
              <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  onClick={() => {
                    setModalCategory("update");
                    setModalTitle(titleModal.edit);
                    setModalShow(true);
                    setManageData({
                      uuid: val.uuid,
                      nama: val.name,
                      deskripsi: val.description,
                      aktif: val.active,
                      amount: val.amount,
                    });
                  }}
                >
                  Ubah
                </button>
                <button
                  className="btn btn-sm btn-danger py-1 px-3 shadow-none mb-0"
                  onClick={() => onDelete(val.uuid)}
                >
                  Hapus
                </button>
              </td>
            </tr>
          );
        })
      );
      setDataLength(dataTable.total);
    }
  }, [dataTable]);

  if (stateLoading) return <LoadingState shadow />;

  return (
    <div className="pt-3 bg-white rounded-3 height-600 shadow">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3">
        <Button
          label={titleModal.save}
          className="btn btn-sm btn-success col-12 col-sm-5 col-md-4 col-lg-3 px-2 shadow-none"
          fontSize={14}
          onClick={() => setModalShow(!modalShow)}
        />
      </div>

      <div
        className="mx-4 mb-3 px-3 pt-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">{titleModal.title}</div>
      </div>

      <div className="d-flex justify-content-end pe-5">
        <SearchTable
        Search={search}
        onChange={setSearch}
        onUpdate={setUpdate}
      />
      </div>

      <div className="px-3 py-2">
        {dataTable !== null && (
          <TablePagination
            title={"Example Pagination From Unsplash"}
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => setPage(page)}
          />
        )}
      </div>

      {data !== null && (
      <ManageMaster
        ModalShow={modalShow}
        handleClose={handleClose}
        ModalTitle={modalTitle}
        ModalCategory={modalCategory}
        data={manageData}
        onDelete={onDelete}
        onSave={onSave}
        onEdit={onEdit}
      />
    )}
    </div>
  );
}

export default MasterDataTemplate;
