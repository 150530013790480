import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFo-SRzeA4aSPFzdZoARkf8Nw_8q_2QPY",
  authDomain: "siskaproject-8e937.firebaseapp.com",
  databaseURL:
    "https://siskaproject-8e937-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "siskaproject-8e937",
  storageBucket: "siskaproject-8e937.appspot.com",
  messagingSenderId: "41410509882",
  appId: "1:41410509882:web:f9a605be93d960bbc18903",
  measurementId: "G-LDQMJXT91H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const database = getDatabase(app);
const messaging = getMessaging(app);

const getTokenFirebase = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BD2YdqhH4899-a92iD75lprIoJX02JwI6IwAOx6NT5SMXJYrb0zcQ-d6UbqyKyCj6VKzewAZcMb7-BTk6CNNqYM",
    // serviceWorkerRegistration:
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound({ status: true, token: currentToken });
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound({ status: false });
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { app, database, messaging, getTokenFirebase, onMessageListener };
