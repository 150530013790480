import React from "react";
import { CustomeDropdown } from "../../atoms";

function FilterDropdown({ title, data, value, setValue }) {
  return (
    <div className="row">
      <div className="fw-bold col-5">
        <i className="fa fa-filter text-warning me-2"></i>
        {title || "Filter"}
        </div>
      <div className="col-7">
        <CustomeDropdown data={data} value={value} setValue={setValue} />
      </div>
    </div>
  );
}

export default FilterDropdown;
