const initialState = {
  dataDPP: null,
};

const dataDppReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_DPP":
      return {
        ...state,
        dataDPP: action.payload,
      };
    default:
      return state;
  }
};

export default dataDppReducer;
