import jsPDF from "jspdf";

// const Doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "" });
// Doc.table(1, 1, generateData(100), headers, { autoSize: true });

export const PdfDocument = (title, data, headers, name) => {
  const Doc = new jsPDF();
  Doc.setFont("arial", "bold");
  Doc.text(title, 105, 15, null, null, "center")
  Doc.setFont("arial", "normal");
  Doc.table(4, 20, data, headers, { fontSize: 6 });
  Doc.save(`${name}.pdf`);
};
