import React from "react";
import { Label } from "../../atoms";

function Footer() {
  return (
    <footer className="footer pt-3 ">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6 mb-lg-0 mb-4">
            <div className="copyright text-center text-sm text-muted text-lg-start">
              <Label fontSize={10}>
                © {new Date().getFullYear()}, Developed by STD Team
              </Label>
              <Label fontSize={10}>
                <b>Version {localStorage.getItem("version")}</b>
              </Label>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
