import React from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { InputLabel } from "../../../../molecules";

function CreateTransactionFilter({
  options,
  paramsData,
  setParamsData,
  setUpdate,
  setMahasiswa,
  mahasiswa,
}) {
  const { classMajorData } = useSelector((state) => state.classMajorReducer);
  const { yearsData } = useSelector((state) => state.yearsReducer);
  const { semesterData } = useSelector((state) => state.semesterReducer);
  const { classCategoryData } = useSelector(
    (state) => state.classCategoryReducer
  );

  return (
    <div className="bg-white rounded-3 shadow">
      <div className="d-flex flex-column p-3 mb-3">
        <div className=" fw-bold fs-6 align-self-center mb-3">
          Data Mahasiswa
        </div>
        <div className="row">
          {paramsData.class_major_id !== 0 ? (
            <InputLabel
              label={"Program Studi"}
              type={"select"}
              data={classMajorData}
              value={paramsData.class_major_id}
              onChange={(e) => {
                setParamsData({ ...paramsData, class_major_id: e });
                setUpdate();
                setMahasiswa(0);
              }}
              grid={"col-md-6 col-lg-3"}
            />
          ) : (
            <LoadingState height={30} />
          )}

          {paramsData.class_category_id !== 0 ? (
            <InputLabel
              label={"Kelas"}
              type={"select"}
              data={classCategoryData}
              value={paramsData.class_category_id}
              onChange={(e) => {
                setParamsData({ ...paramsData, class_category_id: e });
                setUpdate();
                setMahasiswa(0);
              }}
              grid={"col-md-6 col-lg-3"}
            />
          ) : (
            <LoadingState height={30} />
          )}
{/* 
          {paramsData.semester_id !== 0 ? (
            <InputLabel
              label={"Semester"}
              type={"select"}
              data={semesterData}
              value={paramsData.semester_id}
              onChange={(e) => {
                setParamsData({ ...paramsData, semester_id: e });
                setUpdate();
                setMahasiswa(0);
              }}
              grid={"col-md-6 col-lg-3"}
            />
          ) : (
            <LoadingState height={30} />
          )} */}

          {paramsData.year_id !== 0 ? (
            <InputLabel
              label={"Angkatan"}
              type={"select"}
              data={yearsData}
              value={paramsData.year_id}
              onChange={(e) => {
                setParamsData({ ...paramsData, year_id: e });
                setUpdate();
                setMahasiswa(0);
              }}
              grid={"col-md-6 col-lg-3"}
            />
          ) : (
            <LoadingState height={30} />
          )}

          <InputLabel
            label={"Mahasiswa"}
            placeholder={"Pilih mahasiswa"}
            type={"react-select"}
            data={options}
            value={mahasiswa}
            grid={"col-md-6 col-lg-3"}
            onChange={(e) => {
              setMahasiswa(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateTransactionFilter;
