const initialState = {
    semesterData: null,
    semesterFilter: null,
    semesterFilterSelected: null,
  };
  
  const semesterReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_SEMESTER":
        let data = [];
        let filter = [];
  
        action.payload.data.map((val) => {
          if (val.id === 9) {
            data.push({ id: val.id, name: '(20) Konversi' });
          }else{
            data.push({ id: val.id, name: val.semester });
          }
          filter.push({ id: val.id, name: val.semester })
        });

        data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
        filter.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

        filter.unshift({ id: 0, name: "Semua" })

        return {
          ...state,
          semesterData: data,
          semesterFilter:filter,
          semesterFilterSelected: { id: 0, name: "Semua" },
        };
      case "UPDATE_FILTER_SEMESTER":
        return {
          ...state,
          semesterFilterSelected: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default semesterReducer;
  