import Swal from "sweetalert2";

export const handleDelete = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-sm mx-1 shadow-none btn-primary",
      cancelButton: "btn btn-sm mx-1 shadow-none btn-danger",
      popup: "mt-5",
      title: "opacity-7",
    },
    buttonsStyling: false,
  });

  return new Promise((resolve) => {
    swalWithBootstrapButtons
      .fire({
        title: "Anda yakin?",
        text: "ingin menghapus data ini?",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batalkan",
        reverseButtons: true,
        position: "top",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
  });
};

export const handleValidation = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-sm mx-1 shadow-none btn-primary",
      cancelButton: "btn btn-sm mx-1 shadow-none btn-danger",
      popup: "mt-5",
      title: "opacity-7",
    },
    buttonsStyling: false,
  });

  return new Promise((resolve) => {
    swalWithBootstrapButtons
      .fire({
        title: "Anda yakin?",
        text: "ingin mengubah data ini?",
        showCancelButton: true,
        confirmButtonText: "Ya, Ubah",
        cancelButtonText: "Batalkan",
        reverseButtons: true,
        position: "top",
      })
      .then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
  });
};

export const swallDynamic = (icon, title, text) => {
  Swal.fire({
    icon,
    title,
    text,
    customClass: {
      confirmButton: "btn btn-sm shadow-none btn-success",
    },
    timer: 3000,
  });
};
