import React from "react";
import { Label } from "../../atoms";

function Button({ label, disable, onClick, fontSize, fontWeight, ...rest }) {
  return (
    <button {...rest} disabled={disable} onClick={() => onClick()}>
      {disable ? (
        <i className="spinner-border spinner-border-sm"></i>
      ) : (
        <Label fontSize={fontSize} fontWeight={fontWeight}>{label}</Label>
      )}
    </button>
  );
}

export default Button;
