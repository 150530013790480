const initialState = {
    schoolYearData: null,
    schoolYearFilter: null,
    schoolYearFilterSelected: null,
  };
  
  const schoolYearDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_SCHOOL_YEAR":
        let data = [];
        let filter = [{ id: 0, name: "Semua" }]
  
        action.payload.data.map((val) => {
          data.push({ id: val.id, name: val.name });
          filter.push({ id: val.id, name: val.name })
        });
  
        return {
          ...state,
          schoolYearData: data,
          schoolYearFilter:filter,
          schoolYearFilterSelected: { id: 0, name: "Semua" },
        };
      case "UPDATE_SCHOOL_YEAR_FILTER":
        return {
          ...state,
          schoolYearFilterSelected: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default schoolYearDataReducer;
  