import React from "react";

function SearchTable({ Search, onChange, onUpdate }) {
  return (
    <div
      className="position-relative"
      style={{
        width: 200,
      }}
    >
      <div className="position-absolute top-0 end-0 pt-1 pe-2 cursor-pointer"
        onClick={onUpdate}
      >
        <i className="fa fa-search fa-sm "></i>
      </div>
      <input
        type={"text"}
        placeholder="Cari ..."
        className="form-control form-control-sm shadow-none"
        style={{
          backgroundColor: "#E9ECEF",
        }}
        value={Search}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={(e) => (e.key === 'Enter') && onUpdate()}
      />
    </div>
  );
}

export default SearchTable;
