import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDataAPI } from "../../../../configs/redux/action/globalAction";
import { LoadingState } from "../../../../pages/maintenances";
import { formatRupiah, notificationBody } from "../../../../utils";
import { handleDelete } from "../../../../utils/sweetAlert";
import { Gap, Label } from "../../../atoms";
import { Button } from "../../../molecules";
import { TablePagination } from "../../../organisms";
import SettingManageApproval from "./SettingManageApproval";

function SettingTemplate({ data, setUpdate }) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const [dataApproval, setDataApproval] = useState(null);
  const [modalApproval, setModalApproval] = useState(false);
  const [titleModalApproval, setTitleModalApproval] = useState(
    "Tambah Data Kategori Persetujuan"
  );
  const [categoryModalApproval, setCategoryModalApproval] = useState("save");
  const [manageApproval, setManageApproval] = useState(null);
  const dispatch = useDispatch();
  const header = [
    "NAMA",
    "PERWALIAN PERSEN",
    "UTS PERSEN",
    "UAS PERSEN",
    "PERWALIAN RP",
    "UTS RP",
    "UAS RP",
    "AKSI",
  ];

  useEffect(() => {
    if (data.length > 0) {
      setDataApproval(
        data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.name}</td>
              <td>{val.data.approval_perwalian_percent} %</td>
              <td>{val.data.approval_uts_percent} %</td>
              <td>{val.data.approval_uas_percent} %</td>
              <td>
                {formatRupiah(String(val.data.approval_perwalian_amount))}
              </td>
              <td>{formatRupiah(String(val.data.approval_uts_amount))}</td>
              <td>{formatRupiah(String(val.data.approval_uas_amount))}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  onClick={() => {
                    setCategoryModalApproval("update");
                    setTitleModalApproval("Ubah Data Kategori Persetujuan");
                    setModalApproval(true);
                    setManageApproval(val);
                  }}
                >
                  Ubah
                </button>
                <button
                  className="btn btn-sm btn-danger py-1 px-3 shadow-none mb-0 text-white"
                  disabled={val.name === "Global      "}
                  onClick={() => onDelete(val.url.replace('update', 'delete'))}
                >
                  Hapus
                </button>
              </td>
            </tr>
          );
        })
      );
    } else {
      setDataApproval([]);
    }
  }, [data]);

  const onDelete = (url) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI({
            url,
          })
        )
          .then(() => {
            notificationBody("Data berhasil dihapus", "", "success");
            setUpdate();
          })
          .catch(() => {
            notificationBody("Data tidak berhasil dihapus", "", "error");
          });
      }
    });
  };

  if (stateLoading) return <LoadingState />;
  return (
    <div className="pt-3 bg-white rounded-3">
      <div className="height-600 px-3 py-2">
        <div className="d-flex justify-content-between flex-row ">
          <Label fontSize={16} style={{ fontWeight: "bold" }}>
            Data Kategori Persetujuan
          </Label>
          <Button
            label={"Tambah Data"}
            className="btn btn-sm btn-success col-12 col-sm-5 col-md-4 col-lg-3 px-2 shadow-none"
            fontSize={14}
            onClick={() => {
              setManageApproval(null);
              setTitleModalApproval("Tambah Data Kategori Persetujuan");
              setModalApproval(!modalApproval);
            }}
          />
        </div>
        <Gap height={20} />
        {dataApproval !== null && (
          <TablePagination
            title={"Example Pagination From Unsplash"}
            header={header}
            data={dataApproval}
            currentPage={1}
            pageSize={1000}
            totalCount={dataApproval.length}
            setCurrentPage={(page) => {}}
          />
        )}
        <SettingManageApproval
          modalShow={modalApproval}
          handleClose={() => {
            setModalApproval(!modalApproval);
            setManageApproval(null);
          }}
          modalCategory={categoryModalApproval}
          modalTitle={titleModalApproval}
          data={manageApproval}
          onProcess={() => {
            setModalApproval(!modalApproval);
            setUpdate();
          }}
        />
      </div>
    </div>
  );
}

export default SettingTemplate;
