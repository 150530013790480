const initialState = {
    studentData: null,
    studentFilter: null,
    studentFilterSelected: null,
  };
  
  const studentDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_STUDENT":
        let data = [];
        let filter = [{ id: 0, name: "Semua" }]
  
        action.payload.data.map((val) => {
          filter.push({ id: val.id, name: val.name })
        });
        return {
          ...state,
          studentData: action.payload,
          studentFilter: filter,
          studentFilterSelected: { id: 0, name: "Semua" },
        };
      case "UPDATE_STUDENT_FILTER":
        return {
          ...state,
          studentFilterSelected: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default studentDataReducer;
  