const initialState = {
  staging: null,
  environment: null,
  pageLoading: true,
  stateLoading: true,
  productCategoryVal: null,
  menuNavigation: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_GLOBAL":
      return {
        ...state,
        userGlobal: action.payload,
      };
    case "STAGING":
      return {
        ...state,
        staging: action.payload,
      };
    case "ENVIRONMENT":
      return {
        ...state,
        environment: action.payload,
      };
    case "PAGE_LOADING":
      return {
        ...state,
        pageLoading: action.payload,
      };
    case "STATE_LOADING":
      return {
        ...state,
        stateLoading: action.payload,
      };
    case "PRODUCT_CATEGORY_VAL":
      return {
        ...state,
        productCategoryVal: action.payload.data[0],
      };
    case "MENU_NAVIGATION":
      let data = action.payload;
      let menus = [];
      let subMenus = [];
      let navigateJSON = [];

      // console.log(action.payload);
      // data.filter((a) => {
      //   if (a.parent_id === null) {
      //     menus.push(a);
      //   } else {
      //     subMenus.push(a);
      //   }
      // });

      // menus.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      // subMenus.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

      // menus.map((val, key) => {
      //   let subMenu = [];
      //   let keyChild = 0;
      //   subMenus.filter((sub) => {
      //     if (sub.parent_id === val.id) {
      //       let jsonChild = [];
      //       jsonChild.key = keyChild;
      //       jsonChild.path = sub.url;
      //       jsonChild.name = sub.name;
      //       jsonChild.is_sub = sub.is_sub;
      //       jsonChild.icon = sub.icon;
      //       jsonChild.layout = "/admin";
      //       keyChild++;
      //       subMenu.push(jsonChild);
      //     }
      //   });

      //   let json = [];
      //   json.key = key;
      //   json.path = val.url;
      //   json.name = val.name;
      //   json.is_sub = val.is_sub;
      //   json.icon = val.icon;
      //   json.layout = "/admin";
      //   json.drop = subMenu;
      //   navigateJSON.push(json);
      // });

      return {
        ...state,
        menuNavigation: data,
      };
    default:
      return state;
  }
};

export default globalReducer;
