import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SettingTemplate } from "../../../components/templates/admins";
import {
  getDataAPI,
  patchDataAPI,
} from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";

function SettingPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const settingApprovalSetting = dispatch(
      getDataAPI({
        url: environment.api + "/api/applicationsetting/",
      })
    );

    const settingApprovalStudentCategory = dispatch(
      getDataAPI({
        url: environment.api + "/api/student-category/",
      })
    );

    Promise.all([settingApprovalSetting, settingApprovalStudentCategory])
      .then((result) => {
        let dataApproval = [];
        dataApproval.push({
          name: "Global      ",
          data: result[0].data[0],
          url:
            environment.api +
            "/api/applicationsetting/update/" +
            result[0].data[0].uuid,
        });

        result[1].data.map((val) => {
          dataApproval.push({
            name: val.name,
            data: val,
            url: environment.api + "/api/student-category/update/" + val.uuid,
          });
        });

        dispatch({ type: "STATE_LOADING", payload: false });
        setData(dataApproval);
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, [update]);

  return (
    <SettingTemplate data={data} setUpdate={() => setUpdate(update + 1)} />
  );
}

export default SettingPage;
