const initialState = {
  yearsData: null,
  yearsFilter: null,
  yearsFilterSelected: null,
};

const yearsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_YEARS":
      let data = [];
      let filter = [{ id: 0, name: "Semua" }];

      action.payload.data.map((val) => {
        data.push({ id: val.id, name: val.year });
        filter.push({ id: val.id, name: val.year });
      });

      return {
        ...state,
        yearsData: data,
        yearsFilter: filter,
        yearsFilterSelected: { id: 0, name: "Semua" },
      };
    case "UPDATE_FILTER_YEARS":
      return {
        ...state,
        yearsFilterSelected: action.payload,
      };
    default:
      return state;
  }
};

export default yearsReducer;
