import { toast } from "react-toastify";
import { AlertOnBody } from "../components/atoms";

export const formatRupiah = (angka, prefix) => {
  let number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
  return prefix === undefined ? rupiah : rupiah ? prefix + rupiah : "";
};

export const formatNormali = (value) => {
  let str = value;
  str = str.replace(/Rp./, "").replace(/\./g, "");
  return str;
};

export const emaiValidation = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const passwordValidation = (password) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  let errMsg = "success";

  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);

  if (passwordLength === 0) {
    errMsg = "Password tidak boleh kosong";
  } else if (!uppercasePassword) {
    errMsg = "Password harus ada huruf besar";
  } else if (!lowercasePassword) {
    errMsg = "Password harus ada huruf kecil";
  } else if (!digitsPassword) {
    errMsg = "Password harus ada angka";
  } else if (!specialCharPassword) {
    errMsg = "Password harus ada simbol [#?!@$%^&*-]";
  } else if (!minLengthPassword) {
    errMsg = "Password minimal 8 karakter";
  }

  return errMsg;
};

export const approvalStatusData = [
  { id: 0, name: "Semua" },
  { id: "null", name: "Tertunda" },
  { id: true, name: "Disetujui" },
  { id: false, name: "Tidak disetujui" },
];

export const notificationBody = (head, body, type) => {
  toast(<AlertOnBody head={head} body={body} />, {
    position: toast.POSITION.TOP_CENTER,
    theme: "colored",
    type: type || "success",
    hideProgressBar: true,
    closeButton: false,
  });
};
export const convertEpochDate = (epoc) => {
  let date = new Date(epoc);

  let dated = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  return `${dated < 10 ? `0${dated}` : dated}/${
    month < 10 ? `0${month}` : month
  }/${year < 10 ? `0${year}` : year} ${hour < 10 ? `0${hour}` : hour}:${
    minute < 10 ? `0${minute}` : minute
  }:${second < 10 ? `0${second}` : second}`;
};
