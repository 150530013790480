const initialState = {
    userData: null,
    userFilter: null,
    userFilterSelected: null,
  };
  
  const userDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_USER":
        let data = [];
        let filter = [{ id: 0, name: "Semua" }]
  
        action.payload.data.map((val) => {
          filter.push({ id: val.id, name: val.name })
        });
        return {
          ...state,
          userData: action.payload,
          userFilter: filter,
          userFilterSelected: { id: 0, name: "Semua" },
        };
      case "UPDATE_USER_FILTER":
        return {
          ...state,
          userFilterSelected: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userDataReducer;
  