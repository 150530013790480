import React from "react";

function ApprovalNavTab({ data, onChange, value }) {
  return (
    <div className="d-flex">
      <div
        className="d-flex bg-light rounded py-1"
        style={{ fontSize: 14, fontWeight: "bold" }}
      >
        {data.length > 0 &&
          data.map((val, key) => {
            return (
              <div
                key={key}
                className={`d-flex flex-row align-items-center py-1 px-3 mx-1 cursor-pointer hover-li ${
                  val.value === value && "bg-white rounded shadow"
                }`}
                onClick={() => onChange(val.value)}
              >
                <i
                  className="fa fa-book fa-sm me-2"
                  style={{ marginTop: 3 }}
                ></i>
                <div>{val.name}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ApprovalNavTab;
