import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";
import moment from "moment";

const colorsBg = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

const colorsBgTrans = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
];

const ChartLine = () => {
  const { environment } = useSelector((state) => state.globalReducer);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
  };
  const [labels, setLabels] = useState([]);
  const [dataAssets, setDataAssets] = useState([])
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/dashboard/transaction-by-payment-type",
        data: {
          start_date: moment().add(-6, 'month').set('date',1).format('Y-M-DD h:mm:ss'),
          end_date: moment().format('Y-M-DD h:mm:ss'),
        },
      })
    )
      .then((result) => {

        if (result) {
          
          let headerTemp = Object.keys(result);
          let labelTemp = []
          let dataTemp = []
  
          for (let i = 0; i < headerTemp.length; i++) {
            const val = headerTemp[i];
            let labelValTemp =Object.keys(result[val])
            let dataVal = []
            if (i === 0) {
              labelTemp = labelValTemp
            }

            dataVal = labelValTemp.map(value => result[val][value])


             dataTemp.push({
              label : val,
              data: dataVal,
              borderColor: colorsBg[i],
              backgroundColor: colorsBgTrans[i]
             })
          }
  
          setLabels(labelTemp)
          setDataAssets(dataTemp)
        }
      })
      .catch(() => {
        notificationBody("Gagal mendapatkan data", "", "error");
      });

    return () => {};
  }, []);

  const data = {
    labels,
    datasets:dataAssets
  };

  return <Line options={options} data={data} />;
};

export default ChartLine;
