import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

function CustomeDropdown({ data, value, setValue }) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ fontSize: 14 }}
      className="d-flex"
    >
      <div className="bg-primary py-1 px-3 rounded text-white fw-bold cursor-pointer">
        {children}
        &nbsp;&nbsp;&nbsp;
        <i className="fa fa-angle-down"></i>
      </div>
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      let open = className.split(" ");

      return (
        <div>
          <div className={`${className} ${open.length === 1 && "d-none"} `}>
            <div className="position-absolute" style={{ top: -21, left: 15 }}>
              <i className="fa fa-caret-up fa-xl text-white"></i>
            </div>
          </div>
          <div
            ref={ref}
            style={
              (style,
              {
                maxHeight: 200,
                overflow: "auto",
                top: -10,
              })
            }
            className={className + " sidebar-hover"}
            aria-labelledby={labeledBy}
          >
            <ul className="list-unstyled">
            {children}
            </ul>
          </div>
        </div>
      );
    }
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>{value.name.toString().substring(0, 10)} {value.name.length > 7 && '...'} </Dropdown.Toggle>

      <Dropdown.Menu >
        {data.length > 0 &&
          data.map((val, key) => {
            if (value.id === val.id) {
              return (
                <Dropdown.Item key={key} active>
                  {val.name}
                </Dropdown.Item>
              );
            } else {
              return (
                <Dropdown.Item
                  key={key}
                  onClick={() => setValue({ id: val.id, name: val.name })}
                >
                  {val.name}
                </Dropdown.Item>
              );
            }
          })}
      </Dropdown.Menu>
    </Dropdown>

  );
}

export default CustomeDropdown;
