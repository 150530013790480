import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { TablePagination } from "../../../../organisms";
import ManageData from "./ManageData";

function DataTerimaPembayaranTemplate({
  header,
  setPage,
  page,
  size,
  onDelete,
  onSave,
  onEdit,
}) {
  const { stateLoading, environment } = useSelector(
    (state) => state.globalReducer
  );
  const { paymentAccept } = useSelector((state) => state.paymentReducer);
  const [data, setData] = useState(null);
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Tambah Data Terima Pembayaran");
  const [modalCategory, setModalCategory] = useState("save");

  const dataTipe = [
    { id: "Bank", name: "Bank" },
    { id: "Manual", name: "Manual" },
    { id: "Payment Gateway", name: "Payment Gateway" },
  ];

  const dataTipeVal = (val) => {
    for (let i = 0; i < dataTipe.length; i++) {
      const element = dataTipe[i];
      if (element.id === val) {
        return element.name;
      }
    }
    return "tidak terdeteksi";
  };

  const ClearState = () => {
    setModalTitle("Tambah Data Terima Pembayaran");
    setModalCategory("save");
    setManageData(0);
    return setModalShow(false);
  };

  const handleClose = () => {
    if (modalCategory !== "save") {
      ClearState();
    }

    return setModalShow(false);
  };

  useEffect(() => {
    if (paymentAccept !== null) {
      setData(
        paymentAccept.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.title}</td>
              <td>{val.description}</td>
              <td>
                {val.active ? (
                  <div className="bg-gradient-success text-white text-center rounded fw-bold mx-3">
                    Aktif
                  </div>
                ) : (
                  <div className="bg-gradient-danger text-white text-center rounded fw-bold mx-3">
                    Tidak Aktif
                  </div>
                )}
              </td>
              <td>{dataTipeVal(val.type)}</td>
              <td>
                <img
                  src={environment.storage + val.image}
                  alt={val.title}
                  width={"100"}
                />
              </td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  onClick={() => {
                    setModalCategory("update");
                    setModalTitle("Edit Data Terima Pembayaran");
                    setModalShow(true);
                    setManageData({
                      uuid: val.uuid,
                      title: val.title,
                      description: val.description,
                      active: val.active,
                      type: val.type,
                      image: val.image,
                    });
                  }}
                >
                  Ubah
                </button>
                <button
                  className="btn btn-sm btn-danger py-1 px-3 shadow-none mb-0"
                  onClick={() => onDelete(val.uuid)}
                >
                  Hapus
                </button>
              </td>
            </tr>
          );
        })
      );

      setDataLength(paymentAccept.total);
    }
  }, [paymentAccept]);

  if (stateLoading) return <LoadingState shadow />;
  return (
    <div className="pt-3 bg-white rounded-3 min-height-600">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3">
        <button
          className="btn btn-sm btn-success col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 px-2"
          style={{ fontSize: 14 }}
          onClick={() => {
            setModalShow(true);
          }}
        >
          Tambah Data Terima Pembayaran
        </button>
      </div>

      <div className="px-3 py-2">
        {paymentAccept !== null && (
          <TablePagination
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => setPage(page)}
          />
        )}
      </div>

      <ManageData
        ModalShow={modalShow}
        handleClose={handleClose}
        ModalTitle={modalTitle}
        ModalCategory={modalCategory}
        data={manageData}
        onDelete={(e) => onDelete(e)}
        dataTipe={dataTipe}
        onSave={onSave}
        onEdit={onEdit}
      />
    </div>
  );
}

export default DataTerimaPembayaranTemplate;
