import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatNormali, formatRupiah } from "../../../../../utils";
import { InputLabel } from "../../../../molecules";
import { ModalCenter } from "../../../../organisms";

function ManageSpp({
  ModalShow,
  handleClose,
  ModalTitle,
  ModalCategory,
  data,
  onDelete,
  onSave,
  onEdit,
}) {
  const { yearsData } = useSelector((state) => state.yearsReducer);
  const { classCategoryData } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { classMajorData } = useSelector((state) => state.classMajorReducer);
  const { semesterFilter } = useSelector((state) => state.semesterReducer);

  const [Id, setId] = useState(0);
  const [Angkatan, setAngkatan] = useState("");
  const [Kelas, setKelas] = useState("");
  const [Semester, setSemester] = useState("");
  const [ProgramStudi, setProgramStudi] = useState("");
  const [Ammount, setAmmount] = useState();
  const [AmmountError, setAmmountError] = useState("");
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);

  useEffect(() => {
    if (data !== 0) {
      setId(data.id);
      setAngkatan(data.angkatan);
      setProgramStudi(data.studi);
      setKelas(data.kelas);
      setSemester(data.semester);
      setAmmount(formatRupiah(String(data.ammount), "Rp. "));
    } else {
      setId(0);
      setAngkatan(yearsData[0].id);
      setProgramStudi(classMajorData[0].id);
      setKelas(classCategoryData[0].id);
      setSemester(semesterFilter[0].id);
      setAmmount();
      setAmmountError("");
    }
  }, [data]);

  const validation = (val) => {
    let error = 0;
    if (Ammount === undefined) {
      setAmmountError("Minimal Rp 100.000");
      error += 1;
    } else if (Ammount.length < 11) {
      setAmmountError("Minimal Rp 100.000");
      error += 1;
    } else {
      setAmmountError("");
    }

    if (error === 0) {
      if (val === "save") {
        setSave(true);
        save();
      } else {
        setUpdate(true);
        edit();
      }
    }
  };

  const save = () => {
    const data = new FormData();
    data.append("year_id", Angkatan);
    data.append("semester_id", Semester);
    data.append("class_major_id", ProgramStudi);
    data.append("class_category_id", Kelas);
    data.append("amount", formatNormali(Ammount));
    onSave(data, () => {
      setSave(false);
      handleClose();
    });
  };

  const edit = () => {
    const data = new FormData();
    data.append("_method", "PATCH");
    data.append("year_id", Angkatan);
    data.append("semester_id", Semester);
    data.append("class_major_id", ProgramStudi);
    data.append("class_category_id", Kelas);
    data.append("amount", formatNormali(Ammount));
    onEdit(data, Id, () => {
      setUpdate(false);
      handleClose();
    });
  };

  return (
    <ModalCenter
      show={ModalShow}
      handleClose={handleClose}
      size={"md"}
      title={ModalTitle}
      category={ModalCategory}
      onSave={() => {
        validation("save");
      }}
      onUpdate={() => {
        validation("update");
      }}
      onDelete={() => {
        handleClose();
        onDelete(Id);
      }}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row d-flex justify-content-center">
        <div className="col-6">
          <InputLabel
            label={"Angkatan"}
            data={yearsData}
            value={Angkatan}
            type={"select"}
            onChange={(e) => setAngkatan(e)}
          />
        </div>
        <div className="col-6">
          <InputLabel
            label={"Program Studi"}
            data={classMajorData}
            value={ProgramStudi}
            type={"select"}
            onChange={(e) => setProgramStudi(e)}
          />
        </div>
        <div className="col-6">
          <InputLabel
            label={"Kategori Kelas"}
            data={classCategoryData}
            value={Kelas}
            type={"select"}
            onChange={(e) => setKelas(e)}
          />
        </div>
        <div className="col-6">
          <InputLabel
            label={"Semester"}
            data={semesterFilter}
            value={Semester}
            type={"select"}
            onChange={(e) => setSemester(e)}
          />
        </div>
        <div className="col-6">
          <InputLabel
            label={"Biaya Kuliah / Semester"}
            placeholder={"Rp. 100.000"}
            value={formatRupiah(String(Ammount), "Rp. ")}
            type={"text"}
            onChange={(e) => setAmmount(e)}
            error={AmmountError}
          />
        </div>
      </div>
    </ModalCenter>
  );
}

export default ManageSpp;
