const initialState = {
  classCategoryData: null,
  classCategoryFilter: null,
  classCategoryFilterSelected: null,
};

const classCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_CLASS_CATEGORY":
      let data = [];
      let filter = [{ id: 0, name: "Semua" }]

      action.payload.data.map((val) => {
        data.push({ id: val.id, name: val.name });
        filter.push({ id: val.id, name: val.name })
      });
      return {
        ...state,
        classCategoryData: data,
        classCategoryFilter: filter,
        classCategoryFilterSelected: { id: 0, name: "Semua" },
      };
    case "UPDATE_CLASS_CATEGORY_FILTER":
      return {
        ...state,
        classCategoryFilterSelected: action.payload,
      };
    default:
      return state;
  }
};

export default classCategoryReducer;
