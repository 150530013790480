import React from "react";
import logo from "../../../assets/img/logo sttb.png";
import { MenuSidebar } from "../../molecules";

function Sidebar({ routeArray, urlPath }) {
  return (
    <div
      className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 shadow"
      style={{ zIndex: 1 }}
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
        ></i>
        <a
          className="navbar-brand m-0 d-flex justify-content-center align-items-center "
          href="/"
        >
          <img src={logo} style={{ width: 30, height: 30 }} />
          <span className="ms-2 fs-5 fw-bold">SISKA</span>
        </a>
      </div>
      <hr className="horizontal dark mt-0" />
      <MenuSidebar routeArray={routeArray} urlPath={urlPath} />
    </div>
  );
}

export default Sidebar;
