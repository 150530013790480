import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { FilterDropdown } from "../../../../molecules";

function FilterDataSpp({ setData }) {
  const { yearsFilter, yearsFilterSelected } = useSelector(
    (state) => state.yearsReducer
  );
  const { classCategoryFilter, classCategoryFilterSelected } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { classMajorFilter, classMajorFilterSelected } = useSelector(
    (state) => state.classMajorReducer
  );

  const dispatch = useDispatch()

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 mb-1">
        {yearsFilter !== null ? (
          <FilterDropdown
            title={"Angkatan"}
            data={yearsFilter}
            value={yearsFilterSelected}
            setValue={(e) => {
              setData(null);
              dispatch({ type: "UPDATE_FILTER_YEARS", payload: e });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-1">
        {classMajorFilter !== null ? (
          <FilterDropdown
            title={"Program Studi"}
            data={classMajorFilter}
            value={classMajorFilterSelected}
            setValue={(e) => {
              setData(null);
              dispatch({ type: "UPDATE_CLASS_MAJOR_FILTER", payload: e });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-1">
        {classCategoryFilter !== null ? (
          <FilterDropdown
            title={"Kategori Kelas"}
            data={classCategoryFilter}
            value={classCategoryFilterSelected}
            setValue={(e) => {
              setData(null);
              dispatch({
                type: "UPDATE_CLASS_CATEGORY_FILTER",
                payload: e,
              });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
    </div>
  );
}

export default FilterDataSpp;
