import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MasterDataTemplate } from "../../../../components/templates/admins";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../utils";
import { handleDelete } from "../../../../utils/sweetAlert";

function DataMengulang() {
  const { environment } = useSelector((state) => state.globalReducer);
  const { dataDPP } = useSelector((state) => state.dataDppReducer);
  const [ProdukCategoryId, setProdukCategoryId] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [update, setUpdate] = useState(0);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const header = ["NO", "NAMA", "DESKRIPSI", "STATUS", "BIAYA", "Action"];

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/productcategory",
        type: "PRODUCT_CATEGORY_VAL",
        data: {
          name: "Mengulang",
        },
      })
    )
      .then((result) => {
        setProdukCategoryId(result.data[0].uuid);
        dispatch({ type: "STATE_LOADING", payload: false });
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
      });
  }, []);

  useEffect(() => {
    if (ProdukCategoryId !== null) {
      let data = {
        category_id: ProdukCategoryId,
        page,
        per_page: size,
      };

      if (search !== "") {
        data.product_name = search;
        data.product_description = search;
      }

      dispatch(
        getDataAPI(
          {
            url: environment.api + "/api/product",
            type: "GET_DATA_DPP",
            data,
          },
          dispatch
        )
      );
    }
  }, [page, update, ProdukCategoryId]);

  const onDelete = (uuid) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI({
            url: environment.api + "/api/product/delete/" + uuid,
          })
        )
          .then(() => {
            notificationBody("Data berhasil dihapus", "", "success");
            setUpdate(update + 1);
          })
          .catch(() => {
            notificationBody("Data tidak berhasil dihapus", "", "error");
          });
      }
    });
  };

  const onSave = (data, cb) => {
    dispatch(
      postDataAPI({
        url: environment.api + "/api/product/store",
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        return cb();
      });
  };

  const onEdit = (data, id, cb) => {
    dispatch(
      patchDataAPI({
        url: environment.api + "/api/product/update/" + id,
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 200) {
          notificationBody(success.data.message, "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        return cb();
      });
  };

  return (
    <MasterDataTemplate
      header={header}
      size={size}
      page={page}
      setPage={setPage}
      setUpdate={() => setUpdate(update + 1)}
      search={search}
      setSearch={setSearch}
      dataTable={dataDPP}
      titleModal={{
        save: "Tambah Data Mengulang",
        edit: "Edit Data Mengulang",
        title: 'Tabel Data Mengulang'
      }}
      onDelete={onDelete}
      onEdit={onEdit}
      onSave={onSave}
    />
  );
}

export default DataMengulang;
