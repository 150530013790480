const initialState = {
    dataTagihan: null,
  };
  
  const dataTagihanReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_TAGIHAN":
        return {
          ...state,
          dataTagihan: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default dataTagihanReducer;
  