import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { FilterDropdown } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";
import ManagementActionCheckbox from "./ManagementActionCheckbox";
import ManagementActionModal from "./ManagementActionModal";

function ManagementActionTemplate({ data }) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const { roleData, roleFilter, roleFilterSelected } = useSelector(
    (state) => state.roleDataReducer
  );
  const [modal, setModal] = useState(false);
  const [moduleData, setModuleData] = useState(null);
  const dispatch = useDispatch();
  const header = ["NAMA", "LIHAT", "SIMPAN", "UBAH", "HAPUS"];

  useEffect(() => {
    if (data !== null) {
      setModuleData(
        data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.module_name}</td>
              <td>
                {val.index === null ? (
                  <i className="fa fa-xmark ms-3"></i>
                ) : (
                  <ManagementActionCheckbox data={val} update={"index"} />
                )}
              </td>
              <td>
                {val.store === null ? (
                  <i className="fa fa-xmark ms-3"></i>
                ) : (
                  <ManagementActionCheckbox data={val} update={"store"} />
                )}
              </td>
              <td>
                {val.update === null ? (
                  <i className="fa fa-xmark ms-3"></i>
                ) : (
                  <ManagementActionCheckbox data={val} update={"update"} />
                )}
              </td>
              <td>
                {val.destroy === null ? (
                  <i className="fa fa-xmark ms-3"></i>
                ) : (
                  <ManagementActionCheckbox data={val} update={"destroy"} />
                )}
              </td>
            </tr>
          );
        })
      );
    }
  }, [data]);

  if (stateLoading) return <LoadingState shadow />;
  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3">
        <button
          className="btn btn-sm btn-success col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 px-2 shadow-none"
          style={{ fontSize: 14 }}
          onClick={() => {
            // setTipe(DataTipe[0].id);
            setModal(true);
          }}
        >
          Tambah Data Peran
        </button>
      </div>
      <div
        className="mx-4 mb-3 px-3 py-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">
          Tabel Data Peran Pengguna{" "}
          {roleFilterSelected !== null && roleFilterSelected.name}
        </div>
        <div className="row justify-content-between">
          <div className="col-8 col-md-6 col-lg-4 col-xl-4 mb-2 ">
            {roleFilter !== null ? (
              <FilterDropdown
                title={"Peran"}
                data={roleFilter}
                value={roleFilterSelected}
                setValue={(e) => {
                  // setData(null);
                  setModuleData(null);
                  dispatch({ type: "UPDATE_ROLE_FILTER", payload: e });
                }}
              />
            ) : (
              <LoadingState height={20} />
            )}
          </div>
        </div>
        <div className="px-3 pb-4">
          {data !== null ? (
            <TablePagination
              header={header}
              data={moduleData}
              currentPage={1}
              pageSize={1}
              totalCount={1}
              setCurrentPage={(page) => {
                // setData(null);
                // setPage(page);
              }}
            />
          ) : (
            <LoadingState />
          )}
        </div>
      </div>
      <ManagementActionModal
        show={modal}
        handleClose={() => {
          setModal(!modal);
        }}
      />
    </div>
  );
}

export default ManagementActionTemplate;
