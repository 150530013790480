import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTagihanMahasiswaTemplate } from "../../../../components/templates/admins";
import { getDataAPI } from "../../../../configs/redux/action/globalAction";
import { formatRupiah, notificationBody } from "../../../../utils";

function DataTagihanMahasiswaPage() {
  const { environment, stateLoading } = useSelector(
    (state) => state.globalReducer
  );
  const { yearsFilterSelected } = useSelector((state) => state.yearsReducer);
  const { semesterFilterSelected } = useSelector(
    (state) => state.semesterReducer
  );
  const { classCategoryFilterSelected } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { classMajorFilterSelected } = useSelector(
    (state) => state.classMajorReducer
  );
  const [update, setUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const dispatch = useDispatch();

  const header = [
    "NO",
    "NAMA",
    "SEMESTER",
    "KATEGORI KELAS",
    "PROGRAM STUDI",
    "ANGKATAN",
    "SISA TAGIHAN",
    "AKSI",
  ];

  useEffect(() => {
    let yearsFilter = dispatch(
      getDataAPI({
        url: environment.api + "/api/years",
        type: "GET_DATA_YEARS",
      })
    );

    let classMajor = dispatch(
      getDataAPI({
        url: environment.api + "/api/classmajor",
        type: "GET_DATA_CLASS_MAJOR",
      })
    );

    let classCategory = dispatch(
      getDataAPI({
        url: environment.api + "/api/classcategory",
        type: "GET_DATA_CLASS_CATEGORY",
      })
    );

    let semesterData = dispatch(
      getDataAPI({
        url: environment.api + "/api/semesters",
        type: "GET_DATA_SEMESTER",
      })
    );

    Promise.all([yearsFilter, classMajor, classCategory, semesterData])
      .then((result) => {
        dispatch({ type: "STATE_LOADING", payload: false });
        setUpdate(update + 1);
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  useEffect(() => {
    if (
      !stateLoading &&
      yearsFilterSelected !== null &&
      classCategoryFilterSelected !== null &&
      classMajorFilterSelected !== null
    ) {
      let data = {
        page,
        per_page: size,
      };

      if (yearsFilterSelected.id !== 0) data.year = yearsFilterSelected.id;
      if (classMajorFilterSelected.id !== 0)
        data.class_major_id = classMajorFilterSelected.id;
      if (classCategoryFilterSelected.id !== 0)
        data.class_category_id = classCategoryFilterSelected.id;

      dispatch(
        getDataAPI({
          url: environment.api + "/api/reports",
          type: "GET_DATA_TAGIHAN",
          data,
        })
      );
    }
  }, [
    update,
    page,
    yearsFilterSelected,
    classCategoryFilterSelected,
    classMajorFilterSelected,
  ]);

  const exportData = (callBack) => {
    let data = {
      page: 1,
      per_page: 10000,
    };

    if (yearsFilterSelected.id !== 0) data.year = yearsFilterSelected.id;
    if (classMajorFilterSelected.id !== 0)
      data.class_major_id = classMajorFilterSelected.id;
    if (classCategoryFilterSelected.id !== 0)
      data.class_category_id = classCategoryFilterSelected.id;

    dispatch(
      getDataAPI({
        url: environment.api + "/api/reports",
        type: "GET_DATA_TAGIHAN",
        data,
      })
    )
      .then((result) => {
        let dataExport = [];
        let dataExportPdf = [];

        result.data.map((val) => {
          dataExport.push({
            Nama: val.name,
            Semester: val.semester,
            Kelas: val.class_category_name,
            Studi: val.class_major_name,
            Angkatan: val.year,
            "Sisa Tagihan": parseInt(val.amount) * -1,
          });
        });

        result.data.map((val) => {
          dataExportPdf.push({
            Nama: val.name,
            Semester: val.semester,
            Kelas: val.class_category_name,
            Studi: val.class_major_name,
            Angkatan: val.year,
            "Sisa Tagihan": formatRupiah(String(val.amount)),
          });
        });

        callBack({ status: true, data: dataExport, dataPdf: dataExportPdf });
      })
      .catch(() => {
        callBack({ status: false });
      });
  };
  return (
    <DataTagihanMahasiswaTemplate
      header={header}
      page={page}
      size={size}
      setPage={setPage}
      setUpdate={setUpdate}
      exportData={exportData}
    />
  );
}

export default DataTagihanMahasiswaPage;
