import React from "react";
import logo from "../../../assets/img/logo sttb.png";

function LoadingPage() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <img src={logo} className="animate-flicker" alt="logo" />
    </div>
  );
}

export default LoadingPage;
