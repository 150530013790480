import React from "react";

function Label({ fontSize, children, ...rest }) {
  return (
    <div
      style={{
        fontSize: fontSize || 12,
        lineHeight: 1.2,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Label;
