import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AmdinLayout } from "./components/templates/layouts";
import { getTokenFirebase, onMessageListener } from "./configs/firebase";
import {
  getDataAPI,
  getEnv,
  postDataAPI,
} from "./configs/redux/action/globalAction";
import { retrieveSession } from "./configs/redux/action/sessionAction";
import { LoginPage } from "./pages/auths";
import { LoadingPage } from "./pages/maintenances";
import { notificationBody } from "./utils";
import { getRoutes } from "./utils/pageRender";
import moment from "moment";
import 'moment/locale/id';

function App() {
  const { loginPosition } = useSelector((state) => state.sessionReducer);
  const { environment, pageLoading, menuNavigation } = useSelector(
    (state) => state.globalReducer
  );
  const [isTokenFound, setTokenFound] = useState({ status: false });
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  
  useEffect(() => {
    moment.locale('id')
    dispatch(
      getEnv({
        staging: process.env.REACT_APP_STAGING,
        type: "ENVIRONMENT",
      })
    );
  }, []);

  useEffect(() => {
    if (environment !== null) {
      let version = localStorage.getItem("version");
      if (version !== environment.version) {
        localStorage.setItem("version", environment.version);
        return window.location.reload();
      } else {
        dispatch(
          retrieveSession({
            url: environment.api + "/api/auth/me",
          })
        );
      }
    }
  }, [environment]);

  useEffect(() => {
    if (loginPosition) {
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          getTokenFirebase(setTokenFound);
        });
      }

      dispatch(
        getDataAPI({
          url: environment.api + "/api/auth/menu",
          type: "MENU_NAVIGATION",
          data: {
            per_page: 50,
          },
        })
      );
    }
  }, [loginPosition]);

  useEffect(() => {
    if (isTokenFound.status) {
      let data = {
        token: isTokenFound.token,
      };

      dispatch(
        postDataAPI({
          url: environment.api + "/api/notifikasi/save-token",
          data,
        })
      );
    }
  }, [isTokenFound]);

  // onMessageListener()
  //   .then((payload) => {
  //     //must be rendered on state on refresh
  //     notificationBody(
  //       payload.notification.title,
  //       payload.notification.body,
  //       "success"
  //     );
  //     setTimeout(() => {
  //       // call api notif
  //       setUpdate(update + 1);
  //     }, 1000);
  //   })
  //   .catch((err) => {
  //     notificationBody("Gagal Mendapatkan Notif", "error");
  //   });

  if (pageLoading) return <LoadingPage />;

  if (environment !== null) {
    return (
      <BrowserRouter>
        {loginPosition ? (
          menuNavigation.length > 0 ? (
            <Routes>
              <Route element={<AmdinLayout />}>
                {getRoutes(menuNavigation)}
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={`${
                        menuNavigation[0].child.length > 0
                          ? menuNavigation[0].child[0].url
                          : menuNavigation[0].url
                      }`}
                      replace
                    />
                  }
                />
              </Route>
              <Route
                path="*"
                element={
                  <Navigate
                    to={`${
                      menuNavigation[0].child.length > 0
                        ? menuNavigation[0].child[0].url
                        : menuNavigation[0].url
                    }`}
                    replace
                  />
                }
              />
            </Routes>
          ) : (
            <LoadingPage />
          )
        ) : (
          <Routes>
            <Route>
              <Route path="login" index element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </BrowserRouter>
    );
  }
}

export default App;
