import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../configs/redux/action/sessionAction";
import { Label } from "../../atoms";

function OverlayAccount({ name, icon, route }) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [Show, setShow] = useState(false);
  const dispatch = useDispatch();

  const sessionLogout = () => {
    dispatch({ type: "PAGE_LOADING", payload: true });
    dispatch(
      logoutSession(
        {
          url: environment.api + "/api/auth/logout",
        },
        dispatch
      )
    );
  };

  return (
    <button
      className="nav-item dropdown me-3 d-flex border-0 bg-transparent align-items-center cursor-pointer position-relative shadow-none"
      onClick={() => setShow(Show ? false : true)}
      onBlur={() => setShow(false)}
    >
      <i className={`${icon} text-white`}></i>
      <div className="text-white mx-2 d-none d-sm-block fw-bold">
        <Label fontSize={13} fontWeight={"bold"}>
          {name}
        </Label>
      </div>
      {Show && (
        <>
          <i className="fa fa-caret-up position-absolute text-white mt-4 shadow"></i>
          <div
            className="position-absolute top-100 end-2 mt-1 mt-sm-1  bg-white pt-2 pb-1 rounded list-unstyled shadow"
            style={{ minWidth: 150 }}
          >
            {route.map((val, key) => {
              return (
                <div
                  className="hover-li px-1 text-dark text-start ps-3 py-1"
                  onClick={() => (window.location.href = val.url)}
                  key={key}
                >
                  <Label fontSize={14}>{val.name}</Label>
                </div>
              );
            })}
            <div
              className="hover-li px-1 text-dark text-start ps-3 py-1"
              onClick={() => sessionLogout()}
            >
              <Label fontSize={14}>Keluar</Label>
            </div>
          </div>
        </>
      )}
    </button>
  );
}

export default OverlayAccount;
