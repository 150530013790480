import { database, messaging } from "../../firebase";
import { ref, onValue } from "firebase/database";
import axios from "axios";

export const getStage = (payload) => (dispatch) => {
  const starCountRef = ref(database, "environment/staging");
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    dispatch({ type: payload.type, payload: data });
  });
};

export const getEnv = (payload) => (dispatch) => {
  const starCountRef = ref(database, "environment/config/" + payload.staging);
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    dispatch({ type: payload.type, payload: data });
  });
};

export const getNotification = (payload) => (dispatch) => {};

export const getDataAPI = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  return new Promise((resolve, reject) => {
    if (session !== null) {
      axios({
        method: "GET",
        url: payload.url,
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        params: payload.data,
      })
        .then((res) => {
          if (res.status === 200) {
            if (payload.type !== undefined) {
              dispatch({
                type: payload.type,
                payload: res.data.result,
              });
            }

            return resolve(res.data.result);
          }
          return reject(true);
        })
        .catch((err) => {
          return reject(err);
        });
    } else {
      return reject(false);
    }
  });
};

export const postDataAPI = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  const headers =
    payload.contentType === null || payload.contentType === undefined
      ? {
          Authorization: `Bearer ${session.token}`,
        }
      : {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": payload.contentType,
        };

  return new Promise((resolve, reject) => {
    if (session !== null) {
      axios({
        method: "POST",
        url: payload.url,
        headers,
        data: payload.data,
      })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    } else {
      return reject(false);
    }
  });
};

export const patchDataAPI = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  const headers =
    payload.contentType === null || payload.contentType === undefined
      ? {
          Authorization: `Bearer ${session.token}`,
        }
      : {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": payload.contentType,
        };

  return new Promise((resolve, reject) => {
    if (session !== null) {
      axios({
        method: "POST",
        url: payload.url,
        headers,
        data: payload.data,
      })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    } else {
      return reject(false);
    }
  });
};

export const deleteDataAPI = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));

  return new Promise((resolve, reject) => {
    if (session !== null) {
      axios({
        method: "DELETE",
        url: payload.url,
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
        data: payload.data,
      })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    } else {
      return reject(false);
    }
  });
};
