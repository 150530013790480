import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ManagementUserTemplate } from "../../../../components/templates/admins";
import { getDataAPI } from "../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../utils";

function ManagementUserPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const { roleFilterSelected } = useSelector((state) => state.roleDataReducer);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/auth/role",
        type: "GET_DATA_ROLE_WITH_ALL",
      })
    )
      .then((res) => {
        setUpdate(update + 1);
        dispatch({ type: "STATE_LOADING", payload: false });
      })
      .catch((err) => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  useEffect(() => {
    if (update > 0) {
      let data = {
        page,
        per_page: size,
      };
      if (roleFilterSelected.id !== 0) data.role_id = roleFilterSelected.id;

      dispatch(
        getDataAPI({
          url: environment.api + "/api/users",
          data,
        })
      )
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          notificationBody(
            "Kesalahan",
            "Terjadi kesealahan pada saat pengambilan data",
            "error"
          );
        });
    }
  }, [update, roleFilterSelected, page, size]);

  return (
    <ManagementUserTemplate
      data={data}
      page={page}
      size={size}
      setPage={setPage}
      setUpdate={() => setUpdate(update + 1)}
    />
  );
}

export default ManagementUserPage;
