import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashboardTemplate } from "../../../components/templates/admins";
import { getDataAPI } from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";

function DashboardPage() {
  const { environment } = useSelector((state) => state.globalReducer);

  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/dashboard/product",
      })
    )
      .then((res) => {
        let dataSemester = res.semesterFee.count;
        let dataProduct = [];
        res.product.map((val) => {
          let nameProduct = val.name.replace(" ", "_").replace("-", "_");
          dataProduct[[nameProduct]] = val.count;
        });

        setData({
          dataSemester,
          dataProduct,
        });
        setTimeout(() => {
          dispatch({ type: "STATE_LOADING", payload: false });
        }, 1000);
      })
      .catch((err) => {
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  return <DashboardTemplate data={data} />;
}

export default DashboardPage;
