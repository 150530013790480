import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalTemplate } from "../../../../components/templates/admins";
import { getDataAPI } from "../../../../configs/redux/action/globalAction";
import { approvalStatusData, notificationBody } from "../../../../utils";

function ApprovalTeknikBisnisDigitalPage() {
  const { stateLoading, environment } = useSelector(
    (state) => state.globalReducer
  );
  const { yearsFilterSelected } = useSelector((state) => state.yearsReducer);
  const { semesterFilterSelected } = useSelector(
    (state) => state.semesterReducer
  );
  const { classCategoryFilterSelected } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { classMajorFilterSelected } = useSelector(
    (state) => state.classMajorReducer
  );
  const { schoolYearFilterSelected } = useSelector(
    (state) => state.schoolYearDataReducer
  );

  const [update, setUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [approval, setApproval] = useState("perwalian");
  const [status, setStatus] = useState(approvalStatusData[0]);
  const dispatch = useDispatch();

  const dataFilter = [
    { value: "perwalian", name: "Perwalian" },
    { value: "uts", name: "UTS" },
    { value: "uas", name: "UAS" },
  ];

  const header = ["No", "NPM", "NAMA", "STATUS", "TAHUN AJARAN", "AKSI"];

  useEffect(() => {
    let yearsFilter = dispatch(
      getDataAPI({
        url: environment.api + "/api/years",
        type: "GET_DATA_YEARS",
      })
    );

    let classMajor = dispatch(
      getDataAPI({
        url: environment.api + "/api/classmajor",
        type: "GET_DATA_CLASS_MAJOR",
      })
    );

    let classCategory = dispatch(
      getDataAPI({
        url: environment.api + "/api/classcategory",
        type: "GET_DATA_CLASS_CATEGORY",
      })
    );

    let semesterData = dispatch(
      getDataAPI({
        url: environment.api + "/api/semesters",
        type: "GET_DATA_SEMESTER",
      })
    );

    let schoolData = dispatch(
      getDataAPI({
        url: environment.api + "/api/schoolyear",
        type: "GET_DATA_SCHOOL_YEAR",
      })
    );

    Promise.all([
      yearsFilter,
      classMajor,
      classCategory,
      semesterData,
      schoolData,
    ])
      .then((result) => {
        // console.log(result);
        dispatch({ type: "STATE_LOADING", payload: false });
        setUpdate(update + 1);
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  useEffect(() => {
    if (
      !stateLoading &&
      yearsFilterSelected !== null &&
      classCategoryFilterSelected !== null &&
      classMajorFilterSelected !== null &&
      schoolYearFilterSelected !== null
    ) {
      let data = {
        page,
        per_page: size,
        class_major_id: 1,
        tipe: approval,
      };

      if (yearsFilterSelected.id !== 0) data.year_id = yearsFilterSelected.id;
      if (classCategoryFilterSelected.id !== 0)
        data.class_category_id = classCategoryFilterSelected.id;
      if (schoolYearFilterSelected.id !== 0)
        data.school_year_id = schoolYearFilterSelected.id;
      if (status.id !== 0) data.kondisi = status.id;

      dispatch(
        getDataAPI({
          url: environment.api + "/api/approval",
          type: "GET_APPROVAL_DATA",
          data,
        })
      );
    }
  }, [
    update,
    page,
    yearsFilterSelected,
    classCategoryFilterSelected,
    classMajorFilterSelected,
    approval,
    status,
    schoolYearFilterSelected,
  ]);

  return (
    <ApprovalTemplate
      header={header}
      page={page}
      size={size}
      setPage={setPage}
      dataFilter={dataFilter}
      approval={approval}
      setApproval={setApproval}
      title={"Bisnis Digital"}
      status={status}
      setStatus={(e) => setStatus(e)}
      setUpdate={() => setUpdate(update + 1)}
    />
  );
}

export default ApprovalTeknikBisnisDigitalPage;
