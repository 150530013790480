import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  OverlayAccount,
  OverlayNotification,
  OverlaySetting,
} from "../../molecules";

function Navbar({ onShowCanvas, brandText, routeArray }) {
  const { sessionUser } = useSelector((state) => state.sessionReducer);
  const [settingRoute, setSettingRoute] = useState([]);
  const [accountRoute, setAccountRoute] = useState([]);
  const [notificationRoute, setNotificationRoute] = useState([]);

  useEffect(() => {
    let routeSetting = [];
    let routeAccount = [];
    let routeNotification = [];

    for (let i = 0; i < routeArray.length; i++) {
      const element = routeArray[i];
      if (element.is_sub === 3) {
        routeSetting.push(element);
      }
      if (element.is_sub === 2) {
        routeAccount.push(element);
      }
      if (element.is_sub === 4) {
        routeNotification.push(element);
      }
    }

    setSettingRoute(routeSetting);
    setAccountRoute(routeAccount);
    setNotificationRoute(routeNotification);
  }, []);

  return (
    <div
      className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl "
      id="navbarBlur"
      data-scroll="false"
    >
      <div className="container-fluid py-1 px-3 d-flex">
        <div aria-label="breadcrumb">
          <div className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <div className="breadcrumb-item text-sm text-white-50">
              {" "}
              {brandText.menu}
            </div>
            {brandText.submenu !== null ? (
              <div className="breadcrumb-item text-sm text-white">
                {brandText.submenu}
              </div>
            ) : null}
          </div>
          <h6 className="font-weight-bolder text-white mb-0">
            {brandText.submenu !== null ? brandText.submenu : brandText.menu}
          </h6>
        </div>
        <div
          className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            &nbsp;
          </div>
          <div className="navbar-nav justify-content-end">
            <div className="nav-item d-xl-none pe-3 d-flex align-items-center">
              <div
                className="sidenav-toggler-inner cursor-pointer"
                onClick={() => onShowCanvas()}
              >
                <i className="sidenav-toggler-line bg-white"></i>
                <i className="sidenav-toggler-line bg-white"></i>
                <i className="sidenav-toggler-line bg-white"></i>
              </div>
            </div>

            <OverlayAccount
              name={sessionUser.name}
              icon={"fa fa-user"}
              route={accountRoute}
            />

            {settingRoute.length > 0 && (
              <OverlaySetting icon={"fa fa-cog "} data={settingRoute} />
            )}

            {notificationRoute.length > 0 && <OverlayNotification />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
