const initialState = {
  dataBeasiswa: null,
};

const dataBeasiswaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_BEASISWA":
      return {
        ...state,
        dataBeasiswa: action.payload,
      };
    default:
      return state;
  }
};

export default dataBeasiswaReducer;
