import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { formatRupiah, notificationBody } from "../../../../../utils";
import { Button } from "../../../../molecules";
import { ModalExport, TablePagination } from "../../../../organisms";
import DataTagihanMahasiswaFilter from "./DataTagihanMahasiswaFilter";
import DataTagihanMahasiswaModal from "./DataTagihanMahasiswaModal";

function DataTagihanMahasiswaTemplate({ header, setPage, page, size, exportData }) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const { dataTagihan } = useSelector((state) => state.dataTagihanReducer);
  const [data, setData] = useState(null);
  const [manageData, setManageData] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [show, setShow] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [title, setTitle] = useState("");
  const [modalExport, setModalExport] = useState(false);
  const [dataExport, setDataExport] = useState(null);
  const [dataExportPdf, setDataExportPdf] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataTagihan !== null) {
      setData(
        dataTagihan.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{1 + key}</td>
              <td>{val.name}</td>
              <td>{val.semester}</td>
              <td>{val.class_category_name}</td>
              <td>{val.class_major_name}</td>
              <td>{val.year}</td>
              <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  onClick={() => {
                    setTitle(`Data Tagihan / Pembayaran ${val.name}`);
                    setShow(!show);
                    setStudentId(val.id);
                  }}
                >
                  Cek
                </button>
              </td>
            </tr>
          );
        })
      );

      setDataLength(dataTagihan.total);
      return () => {
        dispatch({ type: "GET_DATA_TAGIHAN", payload: null });
      };
    }
  }, [dataTagihan]);

  if (stateLoading) return <LoadingState shadow />;

  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div
        className="mx-4 mb-3 px-3 pt-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">Tabel Data Tagihan Mahasiswa</div>
        <DataTagihanMahasiswaFilter setData={setData} />
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="btn btn-info"
            label={
              <div>
                <i className="fa fa-cloud-download-alt fa-xl me-2"></i> Unduh
                Data
              </div>
            }
            onClick={() => {
              setDataExport(null);
              setModalExport(!modalExport);
              exportData((val) => {
                if (val.status) {
                  setDataExport(val.data)
                  setDataExportPdf(val.dataPdf)
                } else {
                  notificationBody("Data tidak bisa di ekspor", "", "error");
                  setModalExport(false);
                }
              });
            }}
          />
        </div>
        <div className="px-3 pb-4">
          <TablePagination
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => {
              setData(null);
              setPage(page);
            }}
          />
        </div>
        <DataTagihanMahasiswaModal
          title={title}
          show={show}
          handleClose={() => setShow(!show)}
          studentId={studentId}
        />
      </div>
      <ModalExport
        show={modalExport}
        handleClose={() => setModalExport(!modalExport)}
        dataExport={dataExport}
        dataExportPdf={dataExportPdf}
        title={'Data Tagihan Mahasiswa'}
      />
    </div>
  );
}

export default DataTagihanMahasiswaTemplate;
