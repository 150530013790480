import React from "react";

function AlertOnPage({ head, body, background, height }) {
  return (
    <div
      className={`rounded w-100 px-4 py-2 my-2 d-flex justify-content-center flex-column  ${background || "bg-success"}`}
      style={{
        lineHeight: 1.3,
        height: height || 50,
      }}
    >
      <div className="text-white fw-bold text-opacity-50">{head}</div>
      <div className="text-white" style={{ fontSize: 13 }}>
        {body}
      </div>
    </div>
  );
}

export default AlertOnPage;