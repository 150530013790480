import React, { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { AlertOnPage, Gap, Image, Input, Label } from "../../../atoms";
import imglogin from "../../../../assets/img/logo sttb.png";
import { passwordValidation } from "../../../../utils";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button } from "../../../molecules";

function LoginTemplate({ onLogin, errorTitleBody, btnDisable, setBtnDisable }) {
  const [nik, setNik] = useState("kepala.keuangan@sttb.ac.id");
  const [password, setPassword] = useState("Kepalakeuangan123");
  const [nikError, setNikError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const nikRef = useRef(null);
  const passwordRef = useRef(null);
  let token = null;

  const onLoginValidate = () => {
    setBtnDisable(true);
    let error = 0;
    const passwordConfirm = passwordValidation(password);

    if (nik.length === 0) {
      setNikError("username wajib diisi");
      error += 1;
    } else if (nik.length < 5) {
      setNikError("NIK kurang dari 5 karakter");
      error += 1;
    } else {
      setNikError("success");
    }
    if (password.length === 0) {
      setPasswordError("password wajib di isi");
      error += 1;
    } else if (password.length < 5) {
      setPasswordError("password kurang dari 6 karakter");
      error += 1;
    } else {
      setPasswordError("success");
    }
    if (error > 0) {
      return setBtnDisable(false);
    }
    setTimeout(() => {
      onLogin({
        nik,
        password,
        token,
      });
    }, 3000);
  };

  return (
    <div className="bg-white">
      <ToastContainer />
      <div className="container-siska-login">
        <div className="container-fluid">
          <div className="row">
            <div className="pt-6 col-xl-6 col-12">
              <div className="d-flex flex-column mx-2 mx-lg-6 text-black">
                <div className="ps-lg-6  pe-lg-7">
                  <Image src={imglogin} />
                  <Gap height={24} />
                  <Label fontSize={27} fontWeight={"bold"}>
                    SELAMAT DATANG DI SISKA
                  </Label>
                  <Gap height={22} />
                  <Label fontSize={14}>
                    Masukkan NIK dan Password anda untuk ke Aplikasi SISKA
                  </Label>
                  {errorTitleBody !== null ? (
                    <AlertOnPage
                      body={errorTitleBody}
                      background={"bg-danger"}
                    />
                  ) : (
                    <Gap height={65} />
                  )}
                  <Input
                    type={"text"}
                    refComponent={nikRef}
                    value={nik}
                    onChange={(e) => setNik(e)}
                    placeholder={"Nomor Induk Karyawan"}
                    err={nikError}
                    onKeyDown={(e) =>
                      e.key === "Enter" && passwordRef.current.focus()
                    }
                  />
                  <Input
                    type={"password"}
                    refComponent={passwordRef}
                    value={password}
                    onChange={(e) => setPassword(e)}
                    placeholder={"Password"}
                    err={passwordError}
                    onKeyDown={(e) => e.key === "Enter" && onLoginValidate()}
                  />

                  <GoogleReCaptcha
                    onVerify={(e) => {
                      console.log({ token: e });
                      token = e;
                    }}
                  />

                  <Button
                    label={"Masuk"}
                    disable={btnDisable}
                    onClick={() => onLoginValidate()}
                    className={"btn btn-primary w-100 mt-3"}
                    fontSize={14}
                    fontWeight={"bold"}
                  />

                  <Label fontSize={11} className="mt-3 mb-3 mb-xl-0">
                    © 2022, Developed by STD Team
                  </Label>
                </div>
              </div>
            </div>
            <div className="col-6 d-xl-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div className="position-relative h-100 m-3  d-flex flex-column justify-content-center overflow-hidden background-login ">
                <div className="background-login-linear h-100 w-100 px-0 opacity-7"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginTemplate;
