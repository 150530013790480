import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataAPI,
  postDataAPI,
} from "../../../../../configs/redux/action/globalAction";
import { LoadingState } from "../../../../../pages/maintenances";
import {
  formatNormali,
  formatRupiah,
  notificationBody,
} from "../../../../../utils";
import { Button, InputLabel } from "../../../../molecules";
import { ModalCenter, TablePagination } from "../../../../organisms";

function CreateTransactionAddData({
  ModalShow,
  handleClose,
  ModalTitle,
  ModalCategory,
  data,
  onDelete,
  onSuccess,
}) {
  const { environment } = useSelector((state) => state.globalReducer);
  const { productCategory } = useSelector(
    (state) => state.productCategoryReducer
  );
  const { semesterData } = useSelector((state) => state.semesterReducer);
  const { productData } = useSelector((state) => state.productDataReducer);

  const [ProductCategoryData, setProductCategoryData] = useState([]);
  const [ProductCategoryDataSpp, setProductCategoryDataSpp] = useState(0);
  const [ProductCategoryDataPay, setProductCategoryDataPay] = useState(0);
  const [ProductCategoryValue, setProductCategoryValue] = useState(0);
  const [Ammount, setAmmount] = useState();
  const [AmmountError, setAmmountError] = useState("");
  const [semesterDataSelected, setSemesterDataSelected] = useState(1);
  const [productId, setProductId] = useState(1);
  const [Page, setPage] = useState(1);
  const [Size, setSize] = useState(1000);
  const [Data, setData] = useState(null);
  const [ManageData, setManageData] = useState(0);
  const [DataLength, setDataLength] = useState(0);
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const dispatch = useDispatch();

  const header = ["NAMA", "NILAI (Rp)", "AKSI"];

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/productcategory",
        type: "GET_DATA_PRODUCT_CATEGORY",
      })
    );
  }, []);

  useEffect(() => {
    if (productCategory !== null) {
      setProductCategoryValue(productCategory[0].uuid);
      setProductCategoryDataSpp(productCategory[0].uuid);
      setProductCategoryDataPay(
        productCategory[5].uuid
      );
      setProductCategoryData(
        productCategory.map((val) => {
          return { id: val.uuid, name: val.name };
        })
      );
    }
  }, [productCategory]);

  useEffect(() => {
    setData(null);
    if (ProductCategoryValue !== 0) {
      if (ProductCategoryValue === ProductCategoryDataSpp) {
        dispatch(
          getDataAPI({
            url: environment.api + "/api/semesterfee",
            type: "GET_DATA_PRODUCT",
            data: {
              year_id: data.year_id,
              // semester_id: data.semester_id,
              class_major_id: data.class_major_id,
              class_category_id: data.class_category_id,
              page: Page,
              per_page: Size,
            },
          })
        );
      } else {
        dispatch(
          getDataAPI({
            url: environment.api + "/api/product",
            type: "GET_DATA_PRODUCT",
            data: {
              category_id: ProductCategoryValue,
              page: Page,
              per_page: Size,
            },
          })
        );
      }
    }
  }, [ProductCategoryValue]);

  useEffect(() => {
    if (Save) {
      const formData = new FormData();
      formData.append("year_id", data.year_id);
      formData.append("semester_id", semesterDataSelected);
      formData.append("class_major_id", data.class_major_id);
      formData.append("class_category_id", data.class_category_id);
      formData.append("student_id", data.student_id);
      formData.append(
        "product_id",
        ProductCategoryValue === ProductCategoryDataSpp ? 1 : productId
      );
      formData.append("description", "Manual Data");

      if (ProductCategoryValue === ProductCategoryDataPay)
        formData.append("amount", formatNormali(Ammount));

      dispatch(
        postDataAPI({
          url: environment.api + "/api/transaction/manual/create",
          data: formData,
          contentType: "multipart/form-data",
        })
      )
        .then((success) => {
          if (success.status === 201) {
            notificationBody(success.data.message, "", "success");
          }
          setSave(false);
          return onSuccess();
        })
        .catch((err) => {
          setSave(false);
          notificationBody(err.response.data.message, "", "error");
          return handleClose();
        });
    }
  }, [Save]);

  useEffect(() => {
    if (productData !== null) {
      productData.reverse();
      setData(
        productData.map((val, key) => {
          return (
            <tr key={key}>
              <td>
                {ProductCategoryValue === ProductCategoryDataSpp
                  ? `Biaya Semester ${val.semester === 9 ? "20" : val.semester}`
                  : val.name}
              </td>
              <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary py-1 px-3 shadow-none me-2 mb-0"
                  // onClick={() => onSave(val.id)}
                  onClick={() => {
                    if (ProductCategoryValue === ProductCategoryDataSpp) {
                      setSemesterDataSelected(val.semester)
                    }
                    setProductId(val.id)
                    setSave(true)
                  }}
                >
                  Pilih
                </button>
              </td>
            </tr>
          );
        })
      );
      setDataLength(productData.length);
    }
  }, [productData]);

  return (
    <ModalCenter
      show={ModalShow}
      handleClose={handleClose}
      size={"md"}
      title={ModalTitle}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row">
        {productCategory !== null && (
          <InputLabel
            label={"Kategori Data"}
            type={"select"}
            data={ProductCategoryData}
            value={ProductCategoryValue}
            onChange={(e) => {
              setProductCategoryValue(e);
            }}
            grid={"col-12"}
          />
        )}

        {productData !== null &&
        ProductCategoryValue === ProductCategoryDataPay ? (
          <div>
            <InputLabel
              label={"Total di bayarkan"}
              placeholder={"Rp. 100.000"}
              value={formatRupiah(String(Ammount), "Rp. ")}
              type={"text"}
              onChange={(e) => setAmmount(e)}
              error={AmmountError}
              grid={"col-12 col-md-5"}
            />
            <div className="text-end">
              <Button
                label={"Simpan"}
                className="btn btn-sm btn-primary"
                onClick={() => {
                  let total = formatNormali(Ammount);
                  if (total < 100000) {
                    setAmmountError("minimal pembayaran Rp 100.000");
                  } else {
                    setAmmountError("");
                    setProductId(2)
                    setSave(true)
                  }
                }}
                disable={Save}
              />
            </div>
          </div>
        ) : Data === null ? (
          <LoadingState height={100} />
        ) : (
          <>
            {ProductCategoryValue !== ProductCategoryDataSpp && (
              <InputLabel
                label={"Semester"}
                type={"select"}
                data={semesterData}
                value={semesterDataSelected}
                onChange={(e) => {
                  setSemesterDataSelected(e);
                }}
                grid={"col-12"}
              />
            )}
            <TablePagination
              header={header}
              data={Data}
              currentPage={Page}
              pageSize={Size}
              totalCount={DataLength}
              setCurrentPage={(page) => {
                setData(null);
                setPage(page);
              }}
            />
          </>
        )}
      </div>
    </ModalCenter>
  );
}

export default CreateTransactionAddData;
