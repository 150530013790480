import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../molecules";

function ModalCenter({
  show,
  handleClose,
  size,
  title,
  category,
  children,
  onSave,
  onUpdate,
  onDelete,
  Save,
  Update,
  Delete,
}) {
  return (
    <Modal
      show={show}
      size={size || "lg"}
      onHide={handleClose}
      centered
      style={{ zIndex: 99999 }}
    >
      <Modal.Header className="py-2 d-flex justify-content-between">
        <Modal.Title style={{ color: "black", fontSize: 18 }}>
          {title}
        </Modal.Title>
        <div className="fs-1 cursor-pointer" onClick={handleClose}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {category !== undefined && (
        <Modal.Footer className="pb-2 pt-3">
          {category === "save" ? (
            <div className="d-flex justify-content-end">
              <Button
                label={"Tutup"}
                onClick={handleClose}
                className="btn btn-sm btn-secondary me-3"
              />
              <Button
                label={"Simpan"}
                disable={Save}
                onClick={onSave}
                className={"btn btn-sm btn-primary"}
              ></Button>
            </div>
          ) : (
            <div className="d-flex justify-content-between w-100">
              <Button
                label={"Hapus"}
                disable={Delete}
                onClick={onDelete}
                className={"btn btn-sm btn-danger"}
              />
              <Button
                label={"Simpan"}
                disable={Update}
                onClick={onUpdate}
                className={"btn btn-sm btn-primary"}
              />
            </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalCenter;
