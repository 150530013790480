const initialState = {
    productCategory: null,
  };
  
  const productCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_PRODUCT_CATEGORY":
        let data = action.payload.data

        data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

        return {
          ...state,
          productCategory: data,
        };
      default:
        return state;
    }
  };
  
  export default productCategoryReducer;
  