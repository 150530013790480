const initialState = {
    studentCategoryData: null,
    studentCategoryFilter: null,
    studentCategoryFilterSelected: null,
  };
  
  const studentCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_STUDENT_CATEGORY":
        let data = [];
        let filter = [{ id: 'Global', name: "Global" }]
  
        action.payload.data.map((val) => {
          filter.push({ id: val.id, name: val.name })
        });
        return {
          ...state,
          studentCategoryData: action.payload,
          studentCategoryFilter: filter,
          studentCategoryFilterSelected: { id: 'Global', name: "Global" },
        };
      case "UPDATE_STUDENT_CATEGORY_FILTER":
        return {
          ...state,
          studentCategoryFilterSelected: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default studentCategoryReducer;
  