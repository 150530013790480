const initialState = {
  dataSeminar: null,
};

const dataSeminarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_SEMINAR":
      return {
        ...state,
        dataSeminar: action.payload,
      };
    default:
      return state;
  }
};

export default dataSeminarReducer;
