import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import {
  convertEpochDate,
  formatRupiah,
  notificationBody,
} from "../../../../../utils";
import { Button } from "../../../../molecules";
import { ModalExport, TablePagination } from "../../../../organisms";
import DataPembayaranFilter from "./DataPembayaranFilter";

function DataPemabayaranTemplate({
  header,
  setPage,
  page,
  size,
  search,
  setSearch,
  setUpdate,
  exportData,
}) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const { dataTagihan } = useSelector((state) => state.dataTagihanReducer);
  const [data, setData] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const [modalExport, setModalExport] = useState(false);
  const [dataExport, setDataExport] = useState(null);
  const [dataExportPdf, setDataExportPdf] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataTagihan !== null) {
      setData(
        dataTagihan.data.map((val, key) => {
          return (
            <tr key={key}>
              <td>{1 + key}</td>
              <td>{val.npm}</td>
              <td>{val.name}</td>
              <td>{val.semester}</td>
              <td>{val.class_categories_name}</td>
              <td>{val.class_majors_name}</td>
              <td>{val.year_generation}</td>
              <td>
                {val.payment_accept_title || "Data Manual"}
              </td>
              <td>{val.product_name}</td>
              <td>{convertEpochDate(val.created_at)}</td>
              <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
            </tr>
          );
        })
      );

      setDataLength(dataTagihan.total);
    }

    return () => {
      dispatch({ type: "GET_DATA_TAGIHAN", payload: null });
    };
  }, [dataTagihan]);

  if (stateLoading) return <LoadingState />;
  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div
        className="mx-4 mb-3 px-3 pt-3 d-flex flex-column"
        style={{ fontSize: 14 }}
      >
        <div className="fw-bold mb-3">Tabel Data Pembayaran</div>
        <DataPembayaranFilter
          setData={setData}
          search={search}
          setSearch={setSearch}
          setUpdate={setUpdate}
        />
        <div className="d-flex justify-content-end">
          <Button
            className="btn btn-info"
            label={
              <div>
                <i className="fa fa-cloud-download-alt fa-xl me-2"></i> Unduh
                Data
              </div>
            }
            onClick={() => {
              setDataExport(null);
              setModalExport(!modalExport);
              exportData((val) => {
                if (val.status) {
                  setDataExport(val.data)
                  setDataExportPdf(val.dataPdf)
                } else {
                  notificationBody("Data tidak bisa di ekspor", "", "error");
                  setModalExport(false);
                }
              });
            }}
          />
        </div>
        <div className=" pb-4">
          <TablePagination
            header={header}
            data={data}
            currentPage={page}
            pageSize={size}
            totalCount={dataLength}
            setCurrentPage={(page) => {
              setData(null);
              setPage(page);
            }}
          />
        </div>
      </div>
      <ModalExport
        show={modalExport}
        handleClose={() => setModalExport(!modalExport)}
        dataExport={dataExport}
        dataExportPdf={dataExportPdf}
        title={'Data Pembayaran'}
      />
    </div>
  );
}

export default DataPemabayaranTemplate;
