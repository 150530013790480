import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Label } from "../../atoms";

function MenuSidebar({ routeArray, urlPath }) {
  const [Key, setKey] = useState(null);
  const pathname = window.location.pathname;
  const navigate = useNavigate();

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    drop,
    icon,
    path,
  }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (eventKey === Key && drop === false) {
        window.location.reload();
      }

      if (drop === false) {
        navigate(path);
      }
    });

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div
        className="nav-item d-flex px-3 py-3 align-items-center cursor-pointer hover"
        style={{
          borderRadius: 14,
          fontSize: 14,
          fontWeight: isCurrentEventKey ? "bold" : "normal",
          backgroundColor: isCurrentEventKey
            ? drop
              ? "transparent"
              : "#f6f9fc"
            : "transparent",
        }}
        onClick={decoratedOnClick}
      >
        <div
          className="d-flex flex-row position-relative align-items-start hover"
          style={{ width: 180 }}
        >
          <i
            className={`${icon}  me-4 ms-2 `}
            style={{ height: 15, width: 10 }}
          ></i>
          <Label
            fontSize={13}
            fontWeight={isCurrentEventKey ? "bold" : "normal"}
          >
            {children}
          </Label>
          <div className="align-self-end">
            {drop ? (
              isCurrentEventKey ? (
                <i className="fa fa-angle-down position-absolute top-50 end-0 translate-middle-y" />
              ) : (
                <i className="fa fa-angle-right position-absolute top-50 end-0 translate-middle-y" />
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let splitPath = pathname.split("/");
    let path = "/" + splitPath[1];

    for (let i = 0; i < routeArray.length; i++) {
      const element = routeArray[i];
      if (path === element.url) {
        return setKey(element.id);
      }
    }
  }, [pathname]);


  const navigateTo = (path) => {
    navigate(path);
  };


  return (
    <div className="collapse navbar-collapse scroller-hide h-75 sidebar-hover pb-5">
      {routeArray.length > 0 && Key !== null ? (
        <Accordion defaultActiveKey={Key} key={Key} className="px-2 navbar-nav">
          {routeArray.map((route, key) => {
            return (
              route.is_sub === 1 && (
                <div key={key}>
                  <ContextAwareToggle
                    eventKey={route.id}
                    drop={route.child.length > 0 ? true : false}
                    icon={`${route.icon}`}
                    path={route.url}
                  >
                    {route.name}
                  </ContextAwareToggle>

                  {route.child.length > 0 ? (
                    <Accordion.Collapse eventKey={route.id}>
                      <div className="d-flex flex-column ">
                        {route.child.map((dropRoute, dropKey) => {
                          let backgroundColor = "transparent";
                          let path = dropRoute.url;

                          if (pathname === path) {
                            backgroundColor = "#f6f9fc";
                          }

                          return (
                            <div
                              key={dropKey}
                              className="mt-1 py-1 rounded cursor-pointer hover"
                              style={{ backgroundColor }}
                              onClick={() => navigateTo(path)}
                            >
                              <div className="ms-6 ps-3 py-2">
                                <Label
                                  fontSize={13}
                                  className={`${
                                    pathname === path && "fw-bold"
                                  }`}
                                >
                                  {dropRoute.name}
                                </Label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Collapse>
                  ) : null}
                </div>
              )
            );
          })}
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MenuSidebar;
