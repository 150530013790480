import React, { useEffect, useState } from "react";
import { passwordValidation } from "../../../../utils";
import { Button, InputLabel } from "../../../molecules";

function AccountTemplate({ data, onUpdate }) {
  const [user, setUser] = useState({
    name: "",
    identity_number: "",
    email: "",
    password: "",
    repassword: "",
  });
  const [userError, setUserError] = useState({
    name: "",
    identity_number: "",
    email: "",
  });

  const [btnAccount, setBtnAccount] = useState(false);
  const [btnPassword, setBtnPassword] = useState(false);

  useEffect(() => {
    if (data !== null) {
      setUser({
        name: data.name,
        identity_number: data.identity_number,
        email: data.email,
        password: "",
        repassword: "",
      });
    } else {
      setUser({
        name: "",
        identity_number: "",
        email: "",
        password: "",
        repassword: "",
      });
    }
  }, [data]);

  const onValidateAccount = () => {
    let err = 0;
    if (user.name.length < 5) {
      setUserError({ ...userError, name: "nama kurang dari 5 karakter" });
      err++;
    } else {
      setUserError({ ...userError, name: "" });
    }

    if (err === 0) {
      let data = new FormData();
      data.append("_method", "PATCH");
      data.append("name", user.name);
      setBtnAccount(true);
      onUpdate(data, "account", () => {
        setBtnAccount(false);
      });
    }
  };

  const onValidatePassword = () => {
    const passwordConfirm = passwordValidation(user.password);
    const rePasswordConfirm = passwordValidation(user.repassword);

    setUserError({
      name: "",
      identity_number: "",
      email: "",
      password: "",
      repassword: "",
    });

    if (user.password !== user.repassword) {
      return setUserError({
        userError,
        repassword: "Kata sandi tidak sama",
      });
    } else {
      setUserError({ userError, repassword: "" });
    }

    if (passwordConfirm !== "success") {
      return setUserError({ userError, password: passwordConfirm });
    } else {
      setUserError({ userError, password: "" });
    }

    if (rePasswordConfirm !== "success") {
      return setUserError({ userError, repassword: rePasswordConfirm });
    } else {
      setUserError({ userError, repassword: "" });
    }

    let data = new FormData();
    data.append("_method", "PATCH");
    setBtnPassword(true);
    data.append("password", user.name);
    onUpdate(data, "password", () => {
      setBtnPassword(false);
    });
  };

  return (
    <div className="pt-3 bg-white rounded-3 min-height-600 shadow">
      <div style={{ paddingTop: 35 }} className="mx-4 px-3 row">
        <div className="col-12 col-md-6 mb-3 border-end-md height-300 position-relative">
          <h5>Data Akun</h5>
          <InputLabel
            label={"Nomor Karyawan"}
            placeholder={"Nomor Karyawan"}
            value={user.identity_number}
            type={"text"}
            onChange={(e) => setUser({ ...user, identity_number: e })}
            grid={"col-12"}
            error={userError.identity_number}
            disabled
          />
          <InputLabel
            label={"Nama"}
            placeholder={"Nama"}
            value={user.name}
            type={"text"}
            onChange={(e) => setUser({ ...user, name: e })}
            grid={"col-12"}
            error={userError.name}
          />
          <InputLabel
            label={"Email"}
            placeholder={"Email"}
            value={user.email}
            type={"text"}
            onChange={(e) => setUser({ ...user, email: e })}
            grid={"col-12"}
            error={userError.email}
            disabled
          />

          <div className="position-absolute bottom-0 end-0 me-3">
            <Button
              label={"Ubah Data"}
              className="btn btn-sm btn-primary mt-3 mb-2"
              disable={btnAccount}
              style={{ minWidth: 100 }}
              onClick={onValidateAccount}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3 border-top border-top-md-0 pt-3 pt-md-0 height-300 position-relative">
          <h5>Ubah Sandi</h5>
          <InputLabel
            label={"Kata Sandi"}
            placeholder={"Kata Sandi"}
            value={user.password}
            type={"password"}
            onChange={(e) => setUser({ ...user, password: e })}
            error={userError.password}
            grid={"col-12"}
          />
          <InputLabel
            label={"Ulang Kata Sandi"}
            placeholder={"Ulang Kata Sandi"}
            value={user.repassword}
            type={"password"}
            onChange={(e) => setUser({ ...user, repassword: e })}
            error={userError.repassword}
            grid={"col-12"}
          />
          <div className="position-absolute bottom-0 end-0 me-3">
            <Button
              label={"Ubah Sandi"}
              className="btn btn-sm btn-primary mt-3 mb-2"
              disable={btnPassword}
              style={{ minWidth: 100 }}
              onClick={onValidatePassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountTemplate;
