const initialState = {
  loginPosition: false,
  sessionUser: [],
  role: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_CHANGE":
      return {
        ...state,
        loginPosition: action.payload,
      };
    case "ROLE_CHANGE":
      return {
        ...state,
        role: action.payload,
      };
    case "SESSION_CHANGE":
      return {
        ...state,
        loginPosition: action.payload.login,
        sessionUser: action.payload.session,
      };
    default:
      return state;
  }
};

export default sessionReducer;
