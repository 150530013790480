import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTerimaPembayaranTemplate } from "../../../../components/templates/admins";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../utils";
import { handleDelete } from "../../../../utils/sweetAlert";

function DataTerimaPembayaranPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  const header = ["Nama", "Deskripsi", "Aktif", "Type", "Gambar", "Action"];

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/paymentaccept",
        type: "GET_PAYMENT_ACCEPT",
      })
    )
      .then(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
      });
  }, [update, page]);

  const onDelete = (uuid) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI({
            url: environment.api + "/api/paymentaccept/delete/" + uuid,
          })
        )
          .then(() => {
            notificationBody("Data berhasil dihapus", "", "success");
            setUpdate(update + 1);
          })
          .catch(() => {
            notificationBody("Data tidak berhasil dihapus", "", "error");
          });
      }
    });
  };

  const onSave = (data, cb) => {
    dispatch(
      postDataAPI({
        url: environment.api + "/api/paymentaccept/store",
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        return cb();
      });
  };

  const onEdit = (data, id, cb) => {
    dispatch(
      patchDataAPI({
        url: environment.api + "/api/paymentaccept/update/" + id,
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 200) {
          notificationBody("Data berhasil diupdate", "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody("Data tidak berhasil diupdate", "", "error");
        return cb();
      });
  };

  return (
    <DataTerimaPembayaranTemplate
      header={header}
      page={page}
      size={size}
      setUpdate={setUpdate}
      setPage={setPage}
      onDelete={onDelete}
      onSave={onSave}
      onEdit={onEdit}
    />
  );
}

export default DataTerimaPembayaranPage;
