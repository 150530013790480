const initialState = {
    approvalData: null,
  };
  
  const approvalDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_APPROVAL_DATA":
        return {
          ...state,
          approvalData: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default approvalDataReducer;
  