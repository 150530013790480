import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "../../../../../pages/maintenances";
import { FilterDropdown } from "../../../../molecules";

function ApprovalFilter({ dataStatus, valueStatus, onChangeStatus, changeData }) {
  const { yearsFilter, yearsFilterSelected } = useSelector(
    (state) => state.yearsReducer
  );
  const { semesterData, semesterFilterSelected } = useSelector(
    (state) => state.semesterReducer
  );
  const { classCategoryFilter, classCategoryFilterSelected } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { SchoolYearData, schoolYearFilter, schoolYearFilterSelected } =
    useSelector((state) => state.schoolYearDataReducer);

  const dispatch = useDispatch();

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 mb-2">
        {classCategoryFilter !== null ? (
          <FilterDropdown
            title={"Kelas"}
            data={classCategoryFilter}
            value={classCategoryFilterSelected}
            setValue={(e) => {
              changeData()
              dispatch({ type: "UPDATE_CLASS_CATEGORY_FILTER", payload: e });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-2">
        {dataStatus !== null ? (
          <FilterDropdown
            title={"Status"}
            data={dataStatus}
            value={valueStatus}
            setValue={(e) => {
              // changeData()
              //   dispatch({ type: "UPDATE_FILTER_YEARS", payload: e });
              onChangeStatus(e);
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-2">
        {schoolYearFilter !== null ? (
          <FilterDropdown
            title={"Tahun Ajaran"}
            data={schoolYearFilter}
            value={schoolYearFilterSelected}
            setValue={(e) => {
              changeData()
              dispatch({ type: "UPDATE_SCHOOL_YEAR_FILTER", payload: e });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-2">
        {yearsFilter !== null ? (
          <FilterDropdown
            title={"Angkatan"}
            data={yearsFilter}
            value={yearsFilterSelected}
            setValue={(e) => {
              changeData()
              dispatch({ type: "UPDATE_FILTER_YEARS", payload: e });
            }}
          />
        ) : (
          <LoadingState height={20} />
        )}
      </div>
    </div>
  );
}

export default ApprovalFilter;
