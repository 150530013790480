import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDataAPI } from "../../../../configs/redux/action/globalAction";
import {
  formatNormali,
  formatRupiah,
  notificationBody,
} from "../../../../utils";
import { InputLabel } from "../../../molecules";
import { ModalCenter } from "../../../organisms";

function SettingManageApproval({
  modalShow,
  handleClose,
  modalCategory,
  modalTitle,
  data,
  onProcess,
}) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [dataError, setDataError] = useState({
    name: "",
    approval_perwalian_amount: "",
    approval_perwalian_percent: "",
    approval_uts_amount: "",
    approval_uts_percent: "",
    approval_uas_amount: "",
    approval_uas_percent: "",
  });
  const [dataSetting, setDataSetting] = useState({
    url: environment.api + "/api/student-category/store/",
    name: "",
    approval_perwalian_amount: "",
    approval_perwalian_percent: "",
    approval_uts_amount: "",
    approval_uts_percent: "",
    approval_uas_amount: "",
    approval_uas_percent: "",
  });
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data === null) {
      setDataSetting({
        url: environment.api + "/api/student-category/store/",
        name: "",
        approval_perwalian_amount: "",
        approval_perwalian_percent: "",
        approval_uts_amount: "",
        approval_uts_percent: "",
        approval_uas_amount: "",
        approval_uas_percent: "",
      });
    } else {
      setDataSetting({
        url: data.url,
        name: data.name,
        approval_perwalian_amount: data.data.approval_perwalian_amount,
        approval_perwalian_percent: data.data.approval_perwalian_percent,
        approval_uts_amount: data.data.approval_uts_amount,
        approval_uts_percent: data.data.approval_uts_percent,
        approval_uas_amount: data.data.approval_uas_amount,
        approval_uas_percent: data.data.approval_uas_percent,
      });
    }
  }, [data]);

  const onValidate = (status) => {
    let name = dataSetting.name;
    let approval_perwalian_amount = dataSetting.approval_perwalian_amount;
    let approval_perwalian_percent = dataSetting.approval_perwalian_percent;
    let approval_uts_amount = dataSetting.approval_uts_amount;
    let approval_uts_percent = dataSetting.approval_uts_percent;
    let approval_uas_amount = dataSetting.approval_uas_amount;
    let approval_uas_percent = dataSetting.approval_uas_percent;

    let err = 0;
    let errorString = dataError;

    if (name.length < 6) {
      err++;
      errorString.name = "Nama kurang dari 6 karakter";
    } else {
      errorString.name = "";
    }

    if (approval_perwalian_amount.length === 0) {
      err++;
      errorString.approval_perwalian_amount = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_perwalian_amount = "";
    }
    if (approval_perwalian_percent.length === 0) {
      err++;
      errorString.approval_perwalian_percent = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_perwalian_percent = "";
    }
    if (approval_uts_amount.length === 0) {
      err++;
      errorString.approval_uts_amount = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_uts_amount = "";
    }
    if (approval_uts_percent.length === 0) {
      err++;
      errorString.approval_uts_percent = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_uts_percent = "";
    }
    if (approval_uas_amount.length === 0) {
      err++;
      errorString.approval_uas_amount = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_uas_amount = "";
    }
    if (approval_uas_percent.length === 0) {
      err++;
      errorString.approval_uas_percent = "Nilai tidak boleh kosong";
    } else {
      errorString.approval_uas_percent = "";
    }
    if (err === 0) {
      const formData = new FormData();
      if (status === "update") {
        formData.append("_method", "PATCH");
      }
      formData.append("name", name);

      formData.append(
        "approval_perwalian_amount",
        formatNormali(approval_perwalian_amount)
      );
      formData.append("approval_perwalian_percent", approval_perwalian_percent);
      formData.append(
        "approval_uts_amount",
        formatNormali(approval_uts_amount)
      );
      formData.append("approval_uts_percent", approval_uts_percent);
      formData.append(
        "approval_uas_amount",
        formatNormali(approval_uas_amount)
      );
      formData.append("approval_uas_percent", approval_uas_percent);

      dispatch(
        postDataAPI({
          url: dataSetting.url,
          data: formData,
          contentType: "multipart/form-data",
        })
      )
        .then((success) => {
          //   if (success.status === 201) {
          notificationBody(success.data.message, "", "success");
          //   }
          setSave(false);
          setUpdate(false);
          return onProcess();
        })
        .catch((err) => {
          setSave(false);
          setUpdate(false);
          notificationBody(err.response.data.message, "", "error");
          //   return handleClose();
        });
    } else {
      setSave(false);
      setUpdate(false);

      setDataError(errorString);
      setTimeout(() => {
        setRefresh(refresh + 1);
      }, 500);
    }
  };

  return (
    <ModalCenter
      show={modalShow}
      handleClose={handleClose}
      size={"md"}
      title={modalTitle}
      category={modalCategory}
      onSave={() => {
        setSave(true);
        onValidate("save");
      }}
      onUpdate={() => {
        setUpdate(true);
        onValidate("update");
      }}
      onDelete={() => {
        handleClose();
        // onDelete(id);
      }}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row">
        <InputLabel
          disabled={dataSetting.name === "Global      "}
          label={"Nama Kategori"}
          placeholder={"Nama Kategori"}
          value={dataSetting.name}
          type={"text"}
          onChange={(e) => {
            if (dataSetting.name !== "Global      ") {
              setDataSetting({
                ...dataSetting,
                name: e,
              });
            }
          }}
          grid={"col-12"}
          error={dataError.name}
        />

        <InputLabel
          label={"Persetujuan Perwalian (Rp)"}
          placeholder={"Rp 1.000.000"}
          value={formatRupiah(
            String(dataSetting.approval_perwalian_amount),
            "Rp. "
          )}
          type={"text"}
          onChange={(e) =>
            setDataSetting({
              ...dataSetting,
              approval_perwalian_amount: e,
            })
          }
          grid={"col-6"}
          error={dataError.approval_perwalian_amount}
        />
        <InputLabel
          label={"Persetujuan Perwalian Persen (%)"}
          placeholder={"00.00"}
          value={dataSetting.approval_perwalian_percent}
          type={"number"}
          onChange={(e) => {
            if (e < 100) {
              setDataSetting({
                ...dataSetting,
                approval_perwalian_percent: e,
              });
            }
          }}
          grid={"col-6"}
          error={dataError.approval_perwalian_percent}
        />
        <InputLabel
          label={"Persetujuan UTS (Rp)"}
          placeholder={"Rp 1.000.000"}
          value={formatRupiah(String(dataSetting.approval_uts_amount), "Rp. ")}
          type={"text"}
          onChange={(e) =>
            setDataSetting({
              ...dataSetting,
              approval_uts_amount: e,
            })
          }
          grid={"col-6"}
          error={dataError.approval_uts_amount}
        />
        <InputLabel
          label={"Persetujuan UTS Persen (%)"}
          placeholder={"00.00"}
          value={dataSetting.approval_uts_percent}
          type={"number"}
          onChange={(e) => {
            if (e < 100) {
              setDataSetting({
                ...dataSetting,
                approval_uts_percent: e,
              });
            }
          }}
          grid={"col-6"}
          error={dataError.approval_uts_percent}
        />
        <InputLabel
          label={"Persetujuan UAS (Rp)"}
          placeholder={"Rp 1.000.000"}
          value={formatRupiah(String(dataSetting.approval_uas_amount), "Rp. ")}
          type={"text"}
          onChange={(e) =>
            setDataSetting({
              ...dataSetting,
              approval_uas_amount: e,
            })
          }
          grid={"col-6"}
          error={dataError.approval_uas_amount}
        />
        <InputLabel
          label={"Persetujuan UAS Persen (%)"}
          placeholder={"00.00"}
          value={dataSetting.approval_uas_percent}
          type={"number"}
          onChange={(e) => {
            if (e < 100) {
              setDataSetting({
                ...dataSetting,
                approval_uas_percent: e,
              });
            }
          }}
          grid={"col-6"}
          error={dataError.approval_uas_percent}
        />
      </div>
    </ModalCenter>
  );
}

export default SettingManageApproval;
