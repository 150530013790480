import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Gap, Label } from "../../atoms";

function InputLabel({
  label,
  type,
  error,
  placeholder,
  value,
  grid,
  onChange,
  data,
  ...rest
}) {
  const [PasswordShow, setPasswordShow] = useState(false);

  return (
    <Form.Group
      className={`${type === "react-select" ? "mb-2" : "mb-3"} ${grid}`}
      style={{ fontSize: 12 }}
    >
      <Label>{label}</Label>
      <Gap height={2} />
      {type === "select" ? (
        <Form.Select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
          size={"sm"}
          style={{ fontSize: 12 }}
        >
          {data.length > 0 &&
            data.map((val, key) => {
              return (
                <option key={key} value={val.id}>
                  {val.name}
                </option>
              );
            })}
        </Form.Select>
      ) : type === "switch" ? (
        <Form.Check type="switch" onChange={onChange} checked={value} />
      ) : type === "textarea" ? (
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          value={value}
          className=""
          size={"sm"}
          onChange={(e) => onChange(e.target.value)}
          style={{ fontSize: 12 }}
          {...rest}
          rows={2}
        />
      ) : type === "react-select" ? (
        <Select
          options={data}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          styles={{
            control: (provided, state) => ({
              ...provided,
              background: "#fff",
              borderColor: "#d1d1d1",
              minHeight: "30px",
              height: "30px",
              borderRadius: 6,
              paddingTop: 0,
              boxShadow: state.isFocused ? null : null,
            }),

            valueContainer: (provided, state) => ({
              ...provided,
              height: "30px",
              padding: "0 6px",
              fontSize: 12,
            }),

            input: (provided, state) => ({
              ...provided,
              margin: "0px",
            }),
            indicatorSeparator: (state) => ({
              display: "none",
            }),
            indicatorsContainer: (provided, state) => ({
              ...provided,
              height: "30px",
            }),
            option: (base) => ({
              ...base,
              fontSize: 12,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: "dark",
            }),
          }}
        />
      ) : type === "password" ? (
        <div className="position-relative">
          <Form.Control
            type={PasswordShow ? "text" : "password"}
            placeholder={placeholder}
            value={value}
            className=""
            size={"sm"}
            onChange={(e) => onChange(e.target.value)}
            style={{ fontSize: 12 }}
            {...rest}
          />
          <i
            className={`fa ${
              PasswordShow ? "fa-eye" : "fa-eye-slash"
            } text-secondary end-0 position-absolute me-2 cursor-pointer`}
            style={{ top: 10 }}
            onClick={() => setPasswordShow(!PasswordShow)}
          ></i>
        </div>
      ) : (
        <Form.Control
          type={type}
          placeholder={placeholder}
          value={value}
          className=""
          size={"sm"}
          onChange={(e) => onChange(e.target.value)}
          style={{ fontSize: 12 }}
          {...rest}
        />
      )}
      <Form.Text className=" text-danger">{error}</Form.Text>
    </Form.Group>
  );
}

export default InputLabel;
