const initialState = {
    productData: null,
  };
  
  const productDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_PRODUCT":
        let data = action.payload.data

        data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));

        return {
          ...state,
          productData: data,
        };
      default:
        return state;
    }
  };
  
  export default productDataReducer;
  