import { Route } from "react-router-dom";
import {
  AccountPage,
  ApprovalTeknikBisnisDigitalPage,
  ApprovalTeknikDKVPage,
  ApprovalTeknikInformatikaPage,
  ApprovalTeknikInudstriPage,
  CreateTransactionPage,
  DashboardPage,
  DataBeasiswaPage,
  DataBiayaLain,
  DataDppPage,
  DataKelasAkselerasi,
  DataMengulang,
  DataPembayaranPage,
  DataRegistrasi,
  DataSemesterPendek,
  DataSeminarPage,
  DataSertifikasiPage,
  DataSppPage,
  DataStudentPage,
  DataTagihanMahasiswaPage,
  DataTerimaPembayaranPage,
  ManagementActionPage,
  ManagementUserPage,
  NotificationPage,
  SettingPage,
} from "../pages/admins";

export const getRoutes = (AdminRoutes) => {
  return AdminRoutes.map((prop, key) => {
    if (prop.child.length > 0) {
      return prop.child.map((childval, childkey) => {
        return (
          <Route
            path={childval.url}
            key={key + childkey}
            element={<PageComponentRender page={childval} />}
          />
        );
      });
    } else {
      return (
        <Route
          path={prop.url}
          key={key}
          element={<PageComponentRender page={prop} />}
        />
      );
    }
  });
};

function PageComponentRender(props) {
  switch (props.page.name) {
    case "Dashboard":
      return <DashboardPage {...props} />;
    case "Data SPP":
      return <DataSppPage {...props} />;
    case "Data DPP":
      return <DataDppPage {...props} />;
    case "Data Beasiswa":
      return <DataBeasiswaPage {...props} />;
    case "Data Sertifikasi":
      return <DataSertifikasiPage {...props} />;
    case "Data Seminar/Sidang/Wisuda":
      return <DataSeminarPage {...props} />;
    case "Data Registrasi":
      return <DataRegistrasi {...props} />;
    case "Mengulang":
      return <DataMengulang {...props} />;
    case "Semester Pendek":
      return <DataSemesterPendek {...props} />;
    case "Kelas Akselerasi":
      return <DataKelasAkselerasi {...props} />;
    case "Biaya Lain-lain":
      return <DataBiayaLain {...props} />;
    case "Data Tagihan Mahasiswa":
      return <DataTagihanMahasiswaPage {...props} />;
    case "Data Pembayaran Mahasiswa":
      return <DataPembayaranPage {...props} />;
    case "Data Terima Pembayaran":
      return <DataTerimaPembayaranPage {...props} />;
    case "Transaksi Personal":
      return <CreateTransactionPage {...props} />;
    case "Data Mahasiswa":
      return <DataStudentPage {...props} />;
    case "Teknik Industri":
      return <ApprovalTeknikInudstriPage {...props} />;
    case "Teknik Informatika":
      return <ApprovalTeknikInformatikaPage {...props} />;
    case "Design Komunikasi Visual":
      return <ApprovalTeknikDKVPage {...props} />;
    case "Bisnis Digital":
      return <ApprovalTeknikBisnisDigitalPage {...props} />;
    case "Peran dan Aksi":
      return <ManagementActionPage {...props} />;
    case "Manajemen Pengguna":
      return <ManagementUserPage {...props} />;
    case "Manajemen Akun":
      return <AccountPage {...props} />;
    case "Pengaturan":
      return <SettingPage {...props} />;
    case "Notifikasi":
      return <NotificationPage {...props} />;
    default:
      return <DashboardPage {...props} />;
  }
}

export default PageComponentRender;
