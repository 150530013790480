const initialState = {
  dataSertifikasi: null,
};

const dataSertifikasiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_SERTIFIKASI":
      return {
        ...state,
        dataSertifikasi: action.payload,
      };
    default:
      return state;
  }
};

export default dataSertifikasiReducer;
