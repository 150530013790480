const initialState = {
  classMajorData: null,
  classMajorFilter: null,
  classMajorFilterSelected: null,
};

const classMajorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_CLASS_MAJOR":
      let data = [];
      let filter = [{ id: 0, name: "Semua" }]

      action.payload.data.map((val) => {
        data.push({ id: val.id, name: val.name });
        filter.push({ id: val.id, name: val.name })
      });

      return {
        ...state,
        classMajorData: data,
        classMajorFilter:filter,
        classMajorFilterSelected: { id: 0, name: "Semua" },
      };
    case "UPDATE_CLASS_MAJOR_FILTER":
      return {
        ...state,
        classMajorFilterSelected: action.payload,
      };
    default:
      return state;
  }
};

export default classMajorReducer;
