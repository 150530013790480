import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postDataAPI } from "../../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../../utils";
import { Button, InputLabel } from "../../../../molecules";

function ManagementActionModal({ show, handleClose }) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [bntDisable, setBntDisable] = useState(false);
  const dispatch = useDispatch();

  const onUpdate = () => {
    if (name.length < 5) {
      return setNameError("Nama Kurang dari 5 karakter");
    }
    setBntDisable(true);

    let data = new FormData();
    data.append("name", name);

    dispatch(
      postDataAPI({
        url: environment.api + "/api/auth/role/store",
        data,
      })
    )
      .then((success) => {
        if (success.status === 200 || success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        setName("");
        handleClose();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        setName("");
        handleClose();
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={"sm"}
      centered
      style={{ zIndex: 99999 }}
    >
      <Modal.Header className="py-2 d-flex justify-content-between">
        <Modal.Title style={{ color: "black", fontSize: 18 }}>
          Tambah Data Peran
        </Modal.Title>
        <div className="fs-1 cursor-pointer" onClick={handleClose}>
          &times;
        </div>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: 0 }}>
        <InputLabel
          label={"Nama"}
          placeholder={"Nama"}
          value={name}
          type={"text"}
          onChange={(e) => setName(e)}
          error={nameError}
          grid={"col-12"}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center">
          <Button
            label={"Simpan"}
            disable={bntDisable}
            onClick={() => onUpdate()}
            className={"btn btn-sm btn-primary"}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ManagementActionModal;
