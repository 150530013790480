import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataSppTemplate } from "../../../../components/templates/admins";
import {
  deleteDataAPI,
  getDataAPI,
  patchDataAPI,
  postDataAPI,
} from "../../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../../utils";
import { handleDelete } from "../../../../utils/sweetAlert";

function DataSppPage() {
  const { stateLoading, environment } = useSelector(
    (state) => state.globalReducer
  );
  const { yearsFilterSelected } = useSelector((state) => state.yearsReducer);
  const { classCategoryFilterSelected } = useSelector(
    (state) => state.classCategoryReducer
  );
  const { classMajorFilterSelected } = useSelector(
    (state) => state.classMajorReducer
  );

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [update, setUpdate] = useState(0);
  const dispatch = useDispatch();

  const header = [
    "NO",
    "ANGKATAN",
    "SEMESTER",
    "KATEGORI KELAS",
    "PROGRAM STUDI",
    "BIAYA KULIAH / SEMESTER",
    "AKSI",
  ];

  useEffect(() => {
    let yearsFilter = dispatch(
      getDataAPI({
        url: environment.api + "/api/years",
        type: "GET_DATA_YEARS",
      })
    );

    let classMajor = dispatch(
      getDataAPI({
        url: environment.api + "/api/classmajor",
        type: "GET_DATA_CLASS_MAJOR",
      })
    );

    let classCategory = dispatch(
      getDataAPI({
        url: environment.api + "/api/classcategory",
        type: "GET_DATA_CLASS_CATEGORY",
      })
    );

    let semesterData = dispatch(
      getDataAPI({
        url: environment.api + "/api/semesters",
        type: "GET_DATA_SEMESTER",
      })
    );

    Promise.all([yearsFilter, classMajor, classCategory, semesterData])
      .then((result) => {
        dispatch({ type: "STATE_LOADING", payload: false });
        setUpdate(update + 1);
      })
      .catch(() => {
        dispatch({ type: "STATE_LOADING", payload: false });
        notificationBody(
          "Kesalahan",
          "Terjadi kesealahan pada saat pengambilan data",
          "error"
        );
      });
  }, []);

  useEffect(() => {
    if (
      !stateLoading &&
      yearsFilterSelected !== null &&
      classCategoryFilterSelected !== null &&
      classMajorFilterSelected !== null
    ) {
      let data = {
        page,
        per_page: size,
      };

      if (yearsFilterSelected.id !== 0) data.year = yearsFilterSelected.name;
      if (classMajorFilterSelected.id !== 0)
        data.major = classMajorFilterSelected.name;
      if (classCategoryFilterSelected.id !== 0)
        data["class-category"] = classCategoryFilterSelected.name;

      dispatch(
        getDataAPI({
          url: environment.api + "/api/semesterfee",
          type: "GET_DATA_SPP",
          data,
        })
      );
    }
  }, [
    update,
    page,
    yearsFilterSelected,
    classCategoryFilterSelected,
    classMajorFilterSelected,
  ]);

  const onDelete = (uuid) => {
    handleDelete().then((result) => {
      if (result) {
        dispatch(
          deleteDataAPI({
            url: environment.api + "/api/semesterfee/delete/" + uuid,
          })
        )
          .then(() => {
            notificationBody("Data berhasil dihapus", "", "success");
            setUpdate(update + 1);
          })
          .catch(() => {
            notificationBody("Data tidak berhasil dihapus", "", "error");
          });
      }
    });
  };

  const onSave = (data, cb) => {
    dispatch(
      postDataAPI({
        url: environment.api + "/api/semesterfee/store",
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 201) {
          notificationBody(success.data.message, "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody(err.response.data.message, "", "error");
        return cb();
      });
  };

  const onEdit = (data, id, cb) => {
    dispatch(
      patchDataAPI({
        url: environment.api + "/api/semesterfee/update/" + id,
        data,
        contentType: "multipart/form-data",
      })
    )
      .then((success) => {
        if (success.status === 200) {
          notificationBody("Data berhasil diupdate", "", "success");
        }
        setUpdate(update + 1);
        return cb();
      })
      .catch((err) => {
        notificationBody("Data tidak berhasil diupdate", "", "error");
        return cb();
      });
  };

  return (
    <DataSppTemplate
      header={header}
      onDelete={onDelete}
      size={size}
      page={page}
      setPage={setPage}
      setUpdate={() => setUpdate(update + 1)}
      onEdit={onEdit}
      onSave={onSave}
    />
  );
}

export default DataSppPage;
