const initialState = {
  transactionData: null,
  transactionDataDpp: null,
  transactionDataWisuda: null,
  transactionDataRegister: null,
};

const transactionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_TRANSACTION":
      return {
        ...state,
        transactionData: action.payload,
      };
    case "GET_DATA_TRANSACTION_DPP":
      return {
        ...state,
        transactionDataDpp: action.payload,
      };
    case "GET_DATA_TRANSACTION_WISUDA":
      return {
        ...state,
        transactionDataWisuda: action.payload,
      };
    case "GET_DATA_TRANSACTION_REGISTER":
      return {
        ...state,
        transactionDataRegister: action.payload,
      };
    default:
      return state;
  }
};

export default transactionDataReducer;
