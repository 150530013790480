import React, { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { LoginTemplate } from "../../../components/templates/auths";
import { loginSession } from "../../../configs/redux/action/sessionAction";
import { notificationBody } from "../../../utils";

function LoginPage() {
  const { environment } = useSelector((state) => state.globalReducer);
  const [errorTitleBody, setErrorTitleBody] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const dispatch = useDispatch();

  const onLogin = (val) => {
    dispatch(
      loginSession({
        url: environment.api + "/api/auth/login",
        username: val.nik,
        password: val.password,
        token: val.token,
        type: "LOGIN_CHANGE",
      })
    )
      .then(() => {
        notificationBody("Login Sukses", "", "success");
        setBtnDisable(false);
      })
      .catch(() => {
        setErrorTitleBody("Username atau Password Salah !");
        setBtnDisable(false);
      });
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
      <LoginTemplate
        onLogin={onLogin}
        errorTitleBody={errorTitleBody}
        btnDisable={btnDisable}
        setBtnDisable={setBtnDisable}
      />
    </GoogleReCaptchaProvider>
  );
}

export default LoginPage;
