import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../../../../../configs/redux/action/globalAction";
import { LoadingState } from "../../../../../pages/maintenances";
import { convertEpochDate, formatRupiah } from "../../../../../utils";
import { Gap, Label } from "../../../../atoms";
import { FilterDropdown } from "../../../../molecules";
import { TablePagination } from "../../../../organisms";

function DataTagihanMahasiswaModal({
  show,
  size,
  handleClose,
  title,
  studentId,
}) {
  const { environment } = useSelector((state) => state.globalReducer);
  const { transactionData } = useSelector(
    (state) => state.transactionDataReducer
  );
  const [paramsData, setParamsData] = useState({
    page: 1,
    per_page: 10,
  });
  const [data, setData] = useState(null);
  const [dataLength, setDataLength] = useState(0);
  const dispatch = useDispatch();
  const header = [
    "TANGGAL",
    "NAMA",
    "NAMA TRANSAKSI",
    "SEMESTER",
    "METODE",
    "STATUS",
    "AMOUNT",
  ];

  const numDataRow = [
    { id: 5, name: "5" },
    { id: 10, name: "10" },
    { id: 25, name: "25" },
    { id: 50, name: "50" },
    { id: 100, name: "100" },
  ];

  useEffect(() => {
    if (studentId !== 0 && show) {
      let data = paramsData;
      data.student_id = studentId;
      dispatch(
        getDataAPI({
          url: environment.api + "/api/transaction",
          type: "GET_DATA_TRANSACTION",
          data,
        })
      );
    } else {
      dispatch({ type: "GET_DATA_TRANSACTION", payload: null });
    }
  }, [studentId, paramsData, show]);

  useEffect(() => {
    if (transactionData !== null) {
      setData(
        transactionData.data.map((val, key) => {
          return (
            <tr
              key={key}
              className={`text-white ${
                val.amount < 0 ? "bg-info" : val.status ===2?"bg-success": val.status ===1? "bg-warning": "bg-danger"
              }`}
              style={{ fontWeight: "bold", opacity: 0.9 }}
            >
              <td>{convertEpochDate(val.updated_at || val.created_at)}</td>
              <td>{val.product_name}</td>
              <td>{val.product_category_name}</td>
              <td>{val.semester}</td>
              <td>
                {val.payment_accept_title || "Data Manual"}
              </td>
              <td>{val.amount > 0 ? "Pembayaran" : "Tagihan"}</td>
              <td>{formatRupiah(String(val.amount), "Rp. ")}</td>
            </tr>
          );
        })
      );
      setDataLength(transactionData.total);
    }
  }, [transactionData]);

  return (
    <Modal
      show={show}
      size={size || "xl"}
      onHide={() => {
        setParamsData({
          page: 1,
          per_page: 10,
        });
        setData(null);
        handleClose();
      }}
      centered
      style={{ zIndex: 99999 }}
    >
      <Modal.Header className="py-2 d-flex justify-content-between">
        <Modal.Title style={{ color: "black", fontSize: 18 }}>
          {title}
        </Modal.Title>
        <div
          className="fs-1 cursor-pointer"
          onClick={() => {
            setParamsData({
              page: 1,
              per_page: 10,
            });
            setData(null);
            handleClose();
          }}
        >
          &times;
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row d-flex justify-content-between mx-3 align-items-center">
          <div className="col-lg-5 col-12 mb-3">
            <FilterDropdown
              title={"Panjang Data"}
              data={numDataRow}
              value={{
                id: paramsData.per_page,
                name: String(paramsData.per_page),
              }}
              setValue={(e) => {
                setData(null);
                setParamsData({ ...paramsData, per_page: e.id, page:1 });
              }}
            />
          </div>
          <div className="col-lg-7 col-12 border border-1 border-primary px-3 py-2 rounded mb-3">
            <div className="d-flex flex-row justify-content-center">
              <Label
                style={{ fontWeight: "bold", color: "black", fontSize: 14 }}
              >
                Pembayaran Sukses{" "}
                <div className="bg-success p-2 opacity-8"></div>
              </Label>
              <Gap width={10} />
              <Label
                style={{ fontWeight: "bold", color: "black", fontSize: 14 }}
              >
                Pembayaran Pending{" "}
                <div className="bg-warning p-2 opacity-8"></div>
              </Label>
              <Gap width={10} />
              <Label
                style={{ fontWeight: "bold", color: "black", fontSize: 14 }}
              >
                Pembayaran Gagal <div className="bg-danger p-2 opacity-8"></div>
              </Label>
              <Gap width={10} />
              <Label
                style={{ fontWeight: "bold", color: "black", fontSize: 14 }}
              >
                Data Tagihan <div className="bg-info p-2 opacity-8"></div>
              </Label>
            </div>
          </div>
        </div>
        {transactionData !== null ? (
          data !== null ? (
            <TablePagination
              header={header}
              data={data}
              currentPage={paramsData.page}
              pageSize={paramsData.per_page}
              totalCount={dataLength}
              setCurrentPage={(page) => {
                setData(null);
                setParamsData({ ...paramsData, page });
              }}
            />
          ) : (
            <LoadingState height={300} />
          )
        ) : (
          <LoadingState height={300} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DataTagihanMahasiswaModal;
