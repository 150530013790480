import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingState } from "../../../../pages/maintenances";
import { convertEpochDate } from "../../../../utils";
import { Label } from "../../../atoms";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

function NotificationTemplate({ data, more, setParams }) {
  const { stateLoading } = useSelector((state) => state.globalReducer);
  const [dataNotification, setDataNotification] = useState([]);

  useEffect(() => {
    if (data.length !== 0) {
      setDataNotification([...dataNotification, ...data]);
    }
  }, [data]);

  if (stateLoading) return <LoadingState />;
  return (
    <div className="pt-3 bg-white rounded-3">
      <div className="min-height-600 px-3 py-2">
        <div className="d-flex justify-content-between flex-row mb-4">
          <Label fontSize={16} style={{ fontWeight: "bold" }}>
            Data Notifikasi
          </Label>
        </div>

        <InfiniteScroll
          dataLength={dataNotification}
          next={() => setParams()}
          hasMore={more}
          loader={<LoadingState height={50} />}
        >
          {dataNotification.map((val, key) => {
            return (
              <div
                key={key}
                className="d-flex flex-column mb-2 shadow px-3 py-2 rounded"
              >
                <div className="d-flex flex-row justify-content-between">
                  <Label fontSize={16} className="fw-bold">
                    {val.title} - {val.no_order}
                  </Label>
                  {/* <Label fontSize={16}>{convertEpochDate(val.created_at)}</Label> */}
                  <Label fontSize={14}>
                    {moment(new Date(val.created_at), "YYYYMMDD").fromNow()}
                  </Label>
                </div>
                <Label> {val.description}</Label>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default NotificationTemplate;
