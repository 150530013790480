import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel } from "../../../../molecules";
import { ModalCenter } from "../../../../organisms";

function ManageData({
  ModalShow,
  handleClose,
  ModalTitle,
  ModalCategory,
  data,
  onDelete,
  dataTipe,
  onSave,
  onEdit,
}) {
  const { environment } = useSelector((state) => state.globalReducer);
  const [id, setId] = useState(0);
  const [judul, setJudul] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [aktif, setAktif] = useState(true);
  const [tipe, setTipe] = useState("");
  const [gambar, setGambar] = useState("");
  const [gambarUrl, setGambarUrl] = useState("");
  const [judulError, setJudulError] = useState("");
  const [deskripsiError, setDeskripsiError] = useState("");
  const [gambarError, setGambarError] = useState("");
  const [Save, setSave] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const dispatch = useDispatch();

  const imageChange = (val) => {
    setGambar(val[0]);
    setGambarUrl(URL.createObjectURL(val[0]));
  };

  useEffect(() => {
    if (data !== 0) {
      setId(data.uuid);
      setJudul(data.title);
      setDeskripsi(data.description);
      setAktif(data.active);
      setTipe(data.type);
      setGambarUrl(environment.storage + data.image);
    } else {
      setId("");
      setJudul("");
      setDeskripsi("");
      setAktif("");
      setTipe("bank");
      setGambarUrl("");
    }
  }, [data]);

  const validation = (val) => {
    let error = 0;
    if (judul.length < 5) {
      setJudulError("judul kurang dari 5 karakter");
      error += 1;
    } else {
      setJudulError("");
    }

    if (deskripsi.length < 10) {
      setDeskripsiError("deskrispi kurang dari 10 karakter");
      error += 1;
    } else {
      setDeskripsiError("");
    }

    if (gambarUrl === "") {
      setGambarError("gambar tidak boleh kosong");
      error += 1;
    } else {
      setGambarError("");
    }

    if (error === 0) {
      if (val === "save") {
        setSave(true);
        save();
      } else {
        setUpdate(true);
        edit();
      }
    }
  };

  const save = () => {
    const data = new FormData();
    data.append("type", tipe);
    data.append("active", aktif);
    data.append("title", judul);
    data.append("description", deskripsi);
    data.append("image", gambar);
    onSave(data, () => {
      setSave(false);
      handleClose();
    });
  };

  const edit = () => {
    const data = new FormData();
    data.append("_method", "PATCH");
    data.append("type", tipe);
    data.append("active", aktif);
    data.append("title", judul);
    data.append("description", deskripsi);
    if (gambar !== "") data.append("file", gambar);
    onEdit(data, id, () => {
      setUpdate(false);
      handleClose();
    });
  };
  return (
    <ModalCenter
      show={ModalShow}
      handleClose={handleClose}
      size={"lg"}
      title={ModalTitle}
      category={ModalCategory}
      onSave={() => {
        validation("save");
      }}
      onUpdate={() => {
        validation("update");
      }}
      onDelete={() => {
        handleClose();
        onDelete(id);
      }}
      Save={Save}
      Update={Update}
      Delete={Delete}
    >
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-4">
          <div className="m-3 text-center border rounded">
            {gambarUrl === "" ? (
              <FontAwesomeIcon
                icon={faImage}
                size="10x"
                className="opacity-5"
              />
            ) : (
              <img src={gambarUrl} className="img-fluid" />
            )}
          </div>
          <div className="mx-3">
            <input
              type="file"
              className="form-control"
              onChange={(e) => imageChange(e.target.files)}
            />
            <div style={{ fontSize: 12 }} className={"text-danger"}>
              {gambarError}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-8 px-4 px-sm-3 mt-3 mt-sm-0">
          <InputLabel
            label={"Judul"}
            placeholder={"Judul"}
            value={judul}
            type={"text"}
            onChange={(e) => setJudul(e)}
            error={judulError}
            grid={"col-12"}
          />

          <InputLabel
            label={"Deskripsi"}
            placeholder={"Deskripsi"}
            value={deskripsi}
            type={"textarea"}
            onChange={(e) => setDeskripsi(e)}
            error={deskripsiError}
            grid={"col-12"}
          />

          <InputLabel
            label={"Tipe"}
            placeholder={"Tipe"}
            value={tipe}
            type={"select"}
            data={dataTipe}
            onChange={(e) => setTipe(e)}
            grid={"col-md-5 col-lg-4"}
          />

          <InputLabel
            label={"Aktif"}
            placeholder={"Aktif"}
            value={aktif}
            type={"switch"}
            onChange={() => setAktif(aktif ? false : true)}
            grid={"col-6"}
          />
        </div>
      </div>
    </ModalCenter>
  );
}

export default ManageData;
