import axios from "axios";

export const loginSession = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();
    data.append("username", payload.username);
    data.append("password", payload.password);

    axios({
      method: "POST",
      url: payload.url,
      data: {
        email: payload.username,
        password: payload.password,
        token: payload.token
      },
    })
      .then((res) => {
        if (res.status === 202) {
          let session = {
            token: res.data.result,
          };
          localStorage.setItem("session_user", JSON.stringify(session));

          return (window.location.href = "/");
        }
        return resolve(true);
      })
      .catch((err) => {
        return reject(true);
      });
  });
};

export const logoutSession = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  axios({
    method: "POST",
    url: payload.url,
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "SESSION_CHANGE",
          payload: {
            login: false,
            session: []
          },
        });
      }
      return dispatch({ type: "PAGE_LOADING", payload: false });
    })
    .catch((err) => {
      return dispatch({ type: "PAGE_LOADING", payload: false });
    });
};

export const registerSession = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (payload.name === "dimsdeall") {
      return resolve({
        head: "Register Sukses!",
        body: "Silakan cek email anda",
      });
    }

    axios
      .post(payload.url, {
        email: payload.email,
        name: payload.name,
        password: payload.password,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          return resolve({
            head: "Register Sukses!",
            body: "Silakan cek email anda",
          });
        }

        reject({
          head: "Error Register",
          body: "Error terjadi kesalahan di server",
        });
      })
      .catch((err) => {
        reject({
          head: "Error Server 500",
          body: "Error terjadi kesalahan di server",
        });
      });
  });
};

export const retrieveSession = (payload) => (dispatch) => {
  const session = JSON.parse(localStorage.getItem("session_user"));
  if (session !== null) {
    axios({
      method: "GET",
      url: payload.url,
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "SESSION_CHANGE",
            payload: {
              login: true,
              session: res.data.result,
            },
          });
        }
        return dispatch({ type: "PAGE_LOADING", payload: false });
      })
      .catch((err) => {
        return dispatch({ type: "PAGE_LOADING", payload: false });
      });
  } else {
    return dispatch({ type: "PAGE_LOADING", payload: false });
  }
};
