import React, { useState } from "react";

function Input({
  value,
  placeholder,
  onChange,
  refComponent,
  type,
  err,
  ...rest
}) {
  const [Password, setPassword] = useState(
    type === "password" ? "password" : "text"
  );
  return (
    <div className="w-100 position-relative mb-3">
      <input
        ref={refComponent}
        type={type === "password" ? Password : type}
        maxLength={50}
        className={`form-control form-control-lg py-3 ${
          err !== "" && err !== "success" ? "border-danger" : ""
        } ${err === "success" && "border-success"}`}
        style={{ padding: 20 }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      <div className="text-danger " style={{ fontSize: 11 }}>
        {err !== "" && err !== "success" && err}
      </div>

      {type === "password" ? (
        <div
          className="position-absolute top-0 end-0 translate-middle-y me-3 cursor-pointer opacity-7"
          onClick={() =>
            setPassword(Password === "password" ? "text" : "password")
          }
          style={{ marginTop: 29 }}
        >
          {Password === "password" ? (
            <i className="fa fa-eye-slash"></i>
          ) : (
            <i className="fa fa-eye"></i>
          )}
        </div>
      ) : type === "text" ? (
        err !== "" &&
        err !== "success" && (
          <div
            className="position-absolute top-0 end-0 translate-middle-y me-3 opacity-7"
            style={{ marginTop: 27 }}
          >
            <div
              className="border border-2 rounded-circle border-danger d-flex justify-content-center align-items-center"
              style={{ height: 18, width: 18 }}
            >
              {/* <i className="fa-solid fa-exclamation fa-xs text-danger fw-bold"></i> */}
              <div className="text-danger fw-bold" style={{fontSize:12}}>!</div>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

export default Input;
