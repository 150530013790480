import React, { useEffect, useState } from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../../../configs/redux/action/globalAction";
import { notificationBody } from "../../../utils";

const ChartPie = () => {
  const { environment } = useSelector((state) => state.globalReducer);
  const [labels, setLabels] = useState([]);
  const [dataAssets, setDataAssets] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDataAPI({
        url: environment.api + "/api/dashboard/transaction-by-payment-method",
      })
    ).then(result => {
      let labelTemp = Object.keys(result);
      let dataTemp = []

      for (let i = 0; i < labelTemp.length; i++) {
        const val = labelTemp[i];
         dataTemp.push(result[val])
      }

      setLabels(labelTemp)
      setDataAssets(dataTemp)
    })
    .catch(() => {
      notificationBody('Gagal mendapatkan data','','error')
    })
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "# of Votes",
        data: dataAssets,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} />;
};

export default ChartPie;
