import React, { useState } from "react";
import { Label } from "../../atoms";

function OverlaySetting({ icon, data }) {
  const [Show, setShow] = useState(false);
  return (
    <button
      className="nav-item dropdown d-flex border-0 bg-transparent align-items-center cursor-pointer position-relative shadow-none"
      onClick={() => setShow(Show ? false : true)}
      onBlur={() => setShow(false)}
    >
      <i
        className={`${icon} text-white fixed-plugin-button-nav cursor-pointer`}
      ></i>

      {Show && data.length > 0 && (
        <>
          <i className="fa fa-caret-up position-absolute text-white mt-4 shadow"></i>
          <div
            className="position-absolute top-100 end-2 mt-1 mt-sm-1 mt-sm-0 bg-white pt-2 pb-1 rounded list-unstyled"
            style={{ minWidth: 150 }}
          >
            {data.map((val, key) => {
              return (
                <div
                  key={key}
                  className="hover-li px-1 text-dark text-start ps-3 py-1"
                  onClick={() => (window.location.href = val.url)}
                >
                  <Label fontSize={14}>{val.name}</Label>
                </div>
              );
            })}
          </div>
        </>
      )}
    </button>
  );
}

export default OverlaySetting;
